import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IconFlagQuestion } from '@tabler/icons-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import { TextArea } from '../../atoms/inputs/InputTextArea'
import { supportFormSchema } from 'src/enviroment/validation/schema'
import { useCreateSupportTicket } from 'src/enviroment/api/services/supportApi'
import Typography from '../../atoms/Typography'
import FileUploadContainer from '../FileUploadContainer'

export type SupportFormData = z.infer<typeof supportFormSchema>

const RequestProjectDialog = () => {
  const [opened, setOpened] = useState<boolean>(false)
  const { mutateAsync, isLoading } = useCreateSupportTicket()
  const [file, setFile] = useState<File | null>(null)

  const { control, handleSubmit, reset } = useForm<SupportFormData>({
    resolver: zodResolver(supportFormSchema),
  })

  const handleFileSelect = async (selectedFile: File) => {
    setFile(selectedFile)
  }

  const handleFileRemove = () => {
    setFile(null)
  }

  const handleDialogChange = (open: boolean) => {
    setOpened(open)
    if (!open) {
      reset()
      setFile(null)
    }
  }

  const onSubmit = async (data: SupportFormData) => {
    try {
      await mutateAsync({
        ...data,
        file: file || undefined,
      })

      setOpened(false)

      setTimeout(() => {
        reset()
        setFile(null)
      }, 100)
    } catch (error) {
      console.error('Failed to submit support request:', error)
    }
  }

  return (
    <Dialog open={opened} onOpenChange={handleDialogChange}>
      <DialogTrigger asChild>
        <SecondaryButton size="sm" leftIcon={<IconFlagQuestion />}>
          Request Project
        </SecondaryButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="xl">Request project</Typography>
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="mb-2 space-y-4">
            <div>
              <Controller
                name="subject"
                control={control}
                render={({ field: { onChange, ...field }, formState }) => (
                  <Input
                    {...field}
                    onChange={onChange}
                    label="Subject"
                    autoFocus
                    placeholder="Text"
                    type="text"
                    errorMessage={formState.errors.subject?.message}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                name="description"
                control={control}
                render={({ field: { onChange, ...field }, formState }) => (
                  <TextArea
                    {...field}
                    onChange={onChange}
                    label="How we can help you?"
                    placeholder="Type here with what issue you encountered, and we will send you an email response with help on resolving these issues."
                    errorMessage={formState.errors.description?.message}
                  />
                )}
              />
            </div>

            <FileUploadContainer file={file} onFileSelect={handleFileSelect} onFileRemove={handleFileRemove} />
          </div>
          <DialogFooter className="mt-2 sm:justify-end sm:space-x-6">
            <PrimaryButton type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Send'}
            </PrimaryButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default RequestProjectDialog
