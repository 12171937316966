import { ComponentProps, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useEffectOnce } from 'react-use'
import { useSignUp } from 'src/enviroment/api/services/authApi'
import { STORAGE_KEYS } from 'src/enviroment/constants/storageKeys'
import SingUpTemplate from 'src/templates/auth/sign-up/SignUpTemplate'
import { ROUTES } from 'src/types/routes'

export default function SignInPage() {
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useSignUp()
  const invitationToken = new URLSearchParams(window.location.search).get('token')
  const invitedEmail = new URLSearchParams(window.location.search).get('email')
  const handleSubmit: ComponentProps<typeof SingUpTemplate>['onSubmit'] = async ({
    email,
    password,
    confirmPassword,
    isRemember,
    marketingMailing,
  }) => {
    try {
      await mutateAsync({ username: email, password, confirmPassword, isRemember, invitationToken })
      localStorage.setItem(STORAGE_KEYS.MARKETING_MAILING, marketingMailing.toString())
      navigate(ROUTES.PERSONAL_INFO)
      // navigate
    } catch (error) {
      // handle
    }
  }

  const handleForgotPassword = () => {
    navigate(ROUTES.FORGOT_PASSWORD)
  }

  return (
    <SingUpTemplate
      onSubmit={handleSubmit}
      isLoading={isLoading}
      handleForgotPassword={handleForgotPassword}
      invitedEmail={invitedEmail}
    />
  )
}
