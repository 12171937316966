import { IconPolygon } from '@tabler/icons-react'
import { Download } from 'lucide-react'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import Broom from 'src/components/atoms/icons/Broom'
import Typography from 'src/components/atoms/Typography'
import CreateProjectDialog from '../../dialogs/CreateProjectDialog'
import { DrawnAreasState } from 'src/types/mapbox'

export const DrawingControls: React.FC<{
  isDrawing: boolean
  hasSelectedFeatures: boolean
  drawnAreas: DrawnAreasState | null
  onDraw: () => void
  onDelete: () => void
  onDownload: () => void
}> = ({ isDrawing, hasSelectedFeatures, drawnAreas, onDraw, onDelete, onDownload }) => (
  <div className="absolute left-4 top-4 z-10 flex gap-2 p-2 backdrop-blur">
    <PrimaryButton
      size="sm"
      leftIcon={<IconPolygon />}
      onClick={onDraw}
      disabled={isDrawing}
      className="bg-gray-50 text-gray-900 hover:bg-gray-100 disabled:animate-pulse disabled:bg-gray-600 disabled:text-gray-300"
    >
      <Typography variant="base">Draw</Typography>
    </PrimaryButton>
    <PrimaryButton
      className="bg-gray-50 text-gray-900 hover:bg-gray-100 disabled:bg-gray-600 disabled:text-gray-300"
      size="sm"
      onClick={onDelete}
      disabled={!hasSelectedFeatures}
    >
      <Broom className="disabled:text-gray-300" />
    </PrimaryButton>
    <PrimaryButton
      className="bg-gray-50 text-gray-900 hover:bg-gray-100 disabled:bg-gray-600 disabled:text-gray-300"
      size="sm"
      onClick={onDownload}
      disabled={!drawnAreas}
    >
      <Download className="h-5 w-5" />
    </PrimaryButton>
    <CreateProjectDialog />
  </div>
)
