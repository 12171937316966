import React, { FC, useEffect, useMemo, useState } from 'react'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/molecules/Table'
import { IUser } from 'src/types/user'
import { Loader, ChevronDown, ChevronRight } from 'lucide-react'
import { useInView } from 'react-intersection-observer'
import { formatDate } from 'src/enviroment/lib/formatters/format-date'
import { IconCheck } from '@tabler/icons-react'
import { TenantExpandedRow } from '../expandedRow'
import DeleteUserDialog from 'src/components/organisms/dialogs/DeleteUserDialog'
import { format } from 'date-fns'
import EditUserDialog from 'src/components/organisms/dialogs/EditUserByAdminDialog'

export interface UserTableProps {
  users: IUser[]
  isLoading: boolean
  error: unknown
  onApprove: (id: string) => void
  hasNextPageUsers: boolean | undefined
  fetchNextPageUsers: () => void
  isFetchingNextPageUsers: boolean
}

export const UserTable: FC<UserTableProps> = ({
  users,
  isLoading,
  error,
  isFetchingNextPageUsers,
  hasNextPageUsers,
  onApprove,
  fetchNextPageUsers,
}) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({})
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '100px',
  })

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  useEffect(() => {
    if (inView && hasNextPageUsers && !isFetchingNextPageUsers) {
      fetchNextPageUsers()
    }
  }, [inView, hasNextPageUsers, isFetchingNextPageUsers, fetchNextPageUsers])

  const columns = useMemo<ColumnDef<IUser>[]>(
    () => [
      {
        id: 'expand',
        size: 40,
        header: '',
        cell: ({ row }) => (
          <button onClick={() => toggleRow(row.original.id)} className="p-2">
            {expandedRows[row.original.id] ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
          </button>
        ),
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'fullName',
        accessorFn: ({ firstName, lastName }) => `${firstName} ${lastName}`,
        header: 'Name',
        cell: ({
          row: {
            original: { firstName, lastName },
          },
        }) => (
          <span className="font-bold">
            {firstName} {lastName}
          </span>
        ),
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'company',
        header: 'Company',
      },
      {
        accessorKey: 'lastLoginAt',
        header: 'Last Login',
        cell: ({ row }) => format(row.original.lastLoginAt, 'MM/dd/yyyy (HH:mm)'),
      },
      {
        accessorKey: 'createdAt',
        header: 'Registration Date',
        cell: ({ row }) => formatDate(row.original.createdAt),
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            {!row.original.isLetIn && (
              <button
                onClick={() => onApprove(row.original.id)}
                className="cursor-pointer p-2 text-light-green-500 hover:text-light-green-600"
              >
                <IconCheck />
              </button>
            )}
            <EditUserDialog userId={row.original.id} />
            <DeleteUserDialog member={row.original} />
          </div>
        ),
      },
    ],
    [onApprove, expandedRows],
  )
  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading) {
    return (
      <div className="flex h-48 items-center justify-center">
        <Loader className="h-8 w-8 animate-spin text-light-green-600" />
      </div>
    )
  }

  if (error) {
    return <div className="text-center text-destructive">Error loading users</div>
  }

  return (
    <div className="rounded-md">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <React.Fragment key={`group-${row.id}`}>
              <TableRow className={expandedRows[row.original.id] ? 'bg-gray-900 text-white' : ''}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
              {expandedRows[row.original.id] && (
                <TenantExpandedRow
                  key={`expanded-${row.id}`}
                  userId={row.original.id}
                  colSpan={columns.length}
                  description={row.original.description}
                  purpose={row.original.purpose}
                />
              )}
            </React.Fragment>
          ))}
          {users.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} className="text-center">
                No pending users found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {hasNextPageUsers && (
        <div ref={ref} className="h-20 w-full">
          {isFetchingNextPageUsers && (
            <Loader className="mx-auto h-6 w-6 animate-spin text-light-green-600" size={32} />
          )}
        </div>
      )}
    </div>
  )
}
