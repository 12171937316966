import { ComponentProps } from 'react'
import { useNavigate } from 'react-router'
import { useCreateUser } from 'src/enviroment/api/services/userApi'
import { STORAGE_KEYS, trasformStringToBoolean } from 'src/enviroment/constants/storageKeys'
import TellUsAboutYourself from 'src/templates/auth/sign-up/TellUsAboutYourself'
import { ROUTES } from 'src/types/routes'

export default function SignUpPersonalInfoPage() {
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useCreateUser()
  const marketingMailing = localStorage.getItem(STORAGE_KEYS.MARKETING_MAILING) as string | undefined
  const handleSubmit: ComponentProps<typeof TellUsAboutYourself>['onSubmit'] = async ({
    firstName,
    lastName,
    companyName,
    description,
    purpose,
  }) => {
    try {
      const marketingMailingBoolean = trasformStringToBoolean(marketingMailing || 'false')
      const { isLetIn } = await mutateAsync({
        body: {
          firstName,
          lastName,
          description,
          purpose,
          company: companyName,
          marketingMailing: marketingMailingBoolean,
        },
      })

      if (isLetIn) {
        navigate(ROUTES.DASHBOARD)
      } else {
        navigate(ROUTES.REQUEST_SENT)
      }
    } catch (error) {
      // handle
    }
  }

  return <TellUsAboutYourself onSubmit={handleSubmit} isLoading={isLoading} />
}
