import { useEffect, useCallback, useState, useRef } from 'react'
import mapboxgl, { MapMouseEvent, Popup } from 'mapbox-gl'
import { useNavigate } from 'react-router'
import MapboxDraw, { DrawSelectionChangeEvent } from '@mapbox/mapbox-gl-draw'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Project } from 'src/types/projects'
import { DrawingMapProps, DrawnAreasState } from 'src/types/mapbox'
import { G } from '@feltmaps/js-sdk/dist/types-BDviYZ-T'
import { stylesDrawPolygon } from 'src/enviroment/constants/constants'
import * as turf from '@turf/turf'
import { DrawingControls } from 'src/components/organisms/mapbox/draw/DrawingControls'
import { AreasInfo } from 'src/components/organisms/mapbox/draw/AreasInfo'
import { createPopupContent } from './createPopupContent'

mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN

interface ExtendedMapboxProps extends DrawingMapProps {
  initialOptions?: Partial<mapboxgl.MapOptions>
  center?: mapboxgl.LngLatLike
  zoom?: number
  onMapClick?: (event: MapMouseEvent & { features?: mapboxgl.GeoJSONFeature[] }) => void
  projects?: Project[]
  isLoading: boolean
}

const defaultOptions: Partial<mapboxgl.MapOptions> = {
  style: 'mapbox://styles/mapbox/dark-v11',
  center: [0, 0],
  zoom: 2,
  projection: 'globe',
}

const ACTIVE_COUNTRIES = ['GHA', 'AGO', 'MOZ', 'COD', 'ZMB', 'PRT']

const CombinedMapBox: React.FC<ExtendedMapboxProps> = ({
  initialOptions,
  onMapClick,
  center,
  zoom,
  projects = [],
  isLoading,
  onAreaCreated,
}) => {
  const navigate = useNavigate()
  const drawRef = useRef<MapboxDraw | null>(null)
  const mapRef = useRef<mapboxgl.Map | null>(null)
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<mapboxgl.Popup | null>(null)

  // Drawing state
  const [isDrawing, setIsDrawing] = useState(false)
  const [hasSelectedFeatures, setHasSelectedFeatures] = useState(false)
  const [drawnAreas, setDrawnAreas] = useState<DrawnAreasState | null>(null)
  const [countryName, setCountryName] = useState<string | null>(null)

  // Area calculation utilities
  const calculateTotalArea = useCallback((features: GeoJSON.Feature[]) => {
    return features.reduce((total, feature) => total + turf.area(feature), 0) / 10000
  }, [])

  const detectCountry = useCallback(
    (feature: GeoJSON.Feature) => {
      if (!mapRef.current) return

      const center = turf.center(feature)
      const point = center.geometry.coordinates

      const features = mapRef.current.queryRenderedFeatures(mapRef.current.project(point as [number, number]), {
        layers: ['country-fills'],
      })

      const countryFeature = features.find((f) => f.properties?.iso_3166_1_alpha_3)
      if (countryFeature?.properties?.name) {
        setCountryName(countryFeature.properties.name)
      }
    },
    [mapRef],
  )

  // Update areas state
  const updateAreas = useCallback(() => {
    if (!drawRef.current) return

    const allFeatures = drawRef.current.getAll().features
    if (allFeatures.length === 0) {
      setDrawnAreas(null)
      setHasSelectedFeatures(false)
      setCountryName(null)
      return
    }

    const lastFeature = allFeatures[allFeatures.length - 1]
    detectCountry(lastFeature)

    setDrawnAreas({
      features: allFeatures,
      totalHectares: calculateTotalArea(allFeatures),
    })
    setIsDrawing(false)
  }, [calculateTotalArea, detectCountry, drawRef])

  // Drawing actions
  const actions = {
    startDrawing: () => {
      if (!drawRef.current) return
      drawRef.current.changeMode('draw_polygon')
      setIsDrawing(true)
    },

    deleteSelected: () => {
      if (!drawRef.current) return
      const selectedIds = drawRef.current.getSelectedIds()
      if (selectedIds.length > 0) {
        drawRef.current.delete(selectedIds)
        updateAreas()
      }
    },

    handleCreate: () => {
      if (drawnAreas?.features.length) {
        onAreaCreated?.(drawnAreas.features)
        setDrawnAreas(null)
        setCountryName(null)
        drawRef.current?.combineFeatures()
      }
    },

    getDrawnAreasFile: () => {
      if (!drawRef.current) return null
      const features = drawRef.current.getAll()
      if (features.features.length === 0) return null

      return new File([JSON.stringify(features)], 'drawn-areas.geojson', {
        type: 'application/json',
      })
    },

    downloadAreas: () => {
      if (!drawRef.current) return
      const features = drawRef.current.getAll()
      if (features.features.length === 0) return

      const blob = new Blob([JSON.stringify(features)], { type: 'application/json' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'drawn-areas.geojson'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
  }

  // Drawing map events handler
  const handleMapEvents = useCallback(
    (map: mapboxgl.Map) => {
      const isActiveCountry = (features: mapboxgl.GeoJSONFeature[]) =>
        features.some(
          (f) => !f.properties?.iso_3166_1_alpha_3 || ACTIVE_COUNTRIES.includes(f.properties.iso_3166_1_alpha_3),
        )

      map.on('mousedown', (e) => {
        if (drawRef.current?.getMode() !== 'draw_polygon') return

        const features = map.queryRenderedFeatures(e.point, { layers: ['country-fills'] })
        if (!isActiveCountry(features)) {
          drawRef.current.changeMode('simple_select')
          setIsDrawing(false)
          e.preventDefault()
          map.getCanvas().style.cursor = ''
        }
      })

      map.on('mousemove', (e) => {
        if (drawRef.current?.getMode() !== 'draw_polygon') return

        const features = map.queryRenderedFeatures(e.point, { layers: ['country-fills'] })
        map.getCanvas().style.cursor = isActiveCountry(features) ? 'crosshair' : 'not-allowed'
      })

      map.on('draw.modechange', () => {
        const mode = drawRef.current?.getMode()
        setIsDrawing(mode === 'draw_polygon')
        if (mode !== 'draw_polygon') {
          map.getCanvas().style.cursor = ''
        }
      })

      map.on('draw.create', () => {
        updateAreas()
      })
    },
    [drawRef, updateAreas],
  )

  // Projects features conversion
  const getProjectFeatures = useCallback((projectsData: Project[]) => {
    if (!Array.isArray(projectsData)) {
      console.warn('Projects data is not an array:', projectsData)
      return []
    }

    return projectsData
      .filter((project) => project && typeof project.longitude === 'number' && typeof project.latitude === 'number')
      .map((project) => ({
        type: 'Feature' as const,
        geometry: {
          type: 'Point' as const,
          coordinates: [project.longitude, project.latitude],
        },
        properties: project,
      }))
  }, [])

  // Update projects source
  const updateProjectsSource = useCallback(
    (projectsData: Project[]) => {
      if (!mapRef.current) return

      const source = mapRef.current.getSource('projects') as mapboxgl.GeoJSONSource
      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: getProjectFeatures(projectsData),
        })
      }
    },
    [mapRef, getProjectFeatures],
  )

  // Setup map event listeners
  const setupMapEventListeners = useCallback(
    (map: mapboxgl.Map) => {
      // Cluster hover events
      map.on('mouseenter', 'clusters', () => {
        if (map) {
          map.getCanvas().style.cursor = 'pointer'
        }
      })

      map.on('mouseleave', 'clusters', () => {
        if (map) {
          map.getCanvas().style.cursor = ''
        }
      })

      // Point hover events
      map.on('mouseenter', 'unclustered-point', (e) => {
        if (!e.features?.[0] || !map) return

        const feature = e.features[0]
        const coordinates = (
          feature.geometry as { type: 'Point'; coordinates: [number, number] }
        ).coordinates.slice() as [number, number]
        const project = feature.properties as unknown as Project

        map.getCanvas().style.cursor = 'pointer'

        popupRef.current = new Popup({
          closeButton: false,
          closeOnClick: false,
          offset: 25,
          className: 'project-popup',
        })
          .setLngLat(coordinates)
          .setDOMContent(createPopupContent(project))
          .addTo(map)
      })

      map.on('mouseleave', 'unclustered-point', () => {
        if (map) {
          map.getCanvas().style.cursor = ''
        }
        if (popupRef.current) {
          popupRef.current.remove()
        }
      })

      // Point click event
      map.on('click', 'unclustered-point', (e) => {
        if (!e.features?.[0]) return
        const project = e.features[0].properties as unknown as Project
        navigate(`/dashboard/project/${project.id}`)
      })

      // Cluster click event
      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })

        const clickedFeature = features?.[0]
        if (!clickedFeature) return

        const clusterId = clickedFeature.properties?.cluster_id
        const source = map.getSource('projects') as mapboxgl.GeoJSONSource

        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          const coordinates = (clickedFeature.geometry as G).coordinates.slice() as [number, number]

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
          }

          map.easeTo({
            center: coordinates,
            zoom: zoom || map.getZoom() + 1,
            duration: 1500,
            easing: (t) => t * (2 - t),
          })
        })
      })

      if (onMapClick) {
        map.on('click', onMapClick)
      }
    },
    [navigate, onMapClick],
  )

  // Setup map sources and layers
  const setupMapSourcesAndLayers = useCallback(
    (map: mapboxgl.Map) => {
      // Add countries source
      map.addSource('countries', {
        type: 'vector',
        url: 'mapbox://mapbox.country-boundaries-v1',
      })

      // Add country fills layer
      map.addLayer({
        id: 'country-fills',
        type: 'fill',
        source: 'countries',
        'source-layer': 'country_boundaries',
        paint: {
          'fill-color': [
            'case',
            ['in', ['get', 'iso_3166_1_alpha_3'], ['literal', ACTIVE_COUNTRIES]],
            'transparent',
            '#27292c',
          ],
          'fill-outline-color': '#F5F6F6',
        },
      })

      // Add projects source with clustering
      map.addSource('projects', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: getProjectFeatures(projects),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      })

      // Add clusters layer
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'projects',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#2e846a',
          'circle-radius': ['step', ['get', 'point_count'], 20, 5, 30, 10, 40],
        },
      })

      // Add cluster count layer
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'projects',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      })

      // Add unclustered points layer
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'projects',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#2e846a',
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff',
        },
      })
    },
    [getProjectFeatures, projects],
  )

  // Initialize map
  const initializeMap = useCallback(() => {
    if (!mapContainer.current || mapRef.current) return

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      ...defaultOptions,
      ...initialOptions,
      center: center || defaultOptions.center,
      zoom: zoom || defaultOptions.zoom,
      attributionControl: false,
    })

    // Add controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right')
    mapRef.current.addControl(new mapboxgl.FullscreenControl())
    mapRef.current.addControl(new mapboxgl.ScaleControl(), 'bottom-left')

    // Add drawing controls
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      boxSelect: true,
      userProperties: true,
      styles: stylesDrawPolygon,
    })
    mapRef.current.addControl(drawRef.current)

    // Setup map after load
    mapRef.current.on('load', () => {
      if (!mapRef.current) return

      setupMapSourcesAndLayers(mapRef.current)
      setupMapEventListeners(mapRef.current)
      handleMapEvents(mapRef.current)

      // Setup drawing events
      mapRef.current.on('draw.create', updateAreas)
      mapRef.current.on('draw.update', updateAreas)
      mapRef.current.on('draw.delete', updateAreas)
      mapRef.current.on('draw.selectionchange', (e: DrawSelectionChangeEvent) => {
        setHasSelectedFeatures(e.features.length > 0)
      })
    })
    if (projects?.length) {
      const bounds = new mapboxgl.LngLatBounds()
      projects.forEach((project) => {
        bounds.extend([project.longitude, project.latitude])
      })

      mapRef.current.fitBounds(bounds, {
        padding: 50,
        maxZoom: 10,
      })
    }
  }, [])

  // Initialize map on component mount
  useEffect(() => {
    initializeMap()

    return () => {
      if (mapRef.current) {
        if (popupRef.current) {
          popupRef.current.remove()
        }
        mapRef.current.remove()
        mapRef.current = null
      }
    }
  }, [initializeMap, mapRef])

  // Update projects when data changes
  useEffect(() => {
    updateProjectsSource(projects)
  }, [projects])

  return (
    <div className="relative h-full">
      <DrawingControls
        isDrawing={isDrawing}
        hasSelectedFeatures={hasSelectedFeatures}
        drawnAreas={drawnAreas}
        onDraw={actions.startDrawing}
        onDelete={actions.deleteSelected}
        onDownload={actions.downloadAreas}
      />

      {drawnAreas && drawnAreas.features.length > 0 && (
        <AreasInfo drawnAreas={drawnAreas} getDrawnAreasFile={actions.getDrawnAreasFile} countryName={countryName} />
      )}

      <div ref={mapContainer} className="h-full w-full" />
      <style>{`
        .project-popup .mapboxgl-popup-content {
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
          border-radius: 8px;
          padding: 12px;
        }
        .project-popup .mapboxgl-popup-tip {
          border-top-color: rgba(0, 0, 0, 0.8);
        }
      `}</style>
    </div>
  )
}

export default CombinedMapBox
