import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { ROUTES } from 'src/types/routes'
import { useUserInformation } from '../api/services/userApi'
import { STORAGE_KEYS } from '../constants/storageKeys'
import { UserRole } from 'src/types/user'
import { useTenantStore } from '../store/tenentStore'

const useAuth = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isAuthenticated = Boolean(
    localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN) || sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN),
  )
  const { currentTenant, clear } = useTenantStore()
  const { data: user, isLoading, isError } = useUserInformation()
  const isSuperAdmin = user?.isSuperadmin

  const userRole = currentTenant?.tenantMemberAccess?.role || UserRole.VIEWER
  const onboardingStatus = user?.onboarding
  const logout = async () => {
    queryClient.clear()
    clear()
    localStorage.clear()
    sessionStorage.clear()
    navigate(ROUTES.SIGN_IN)
  }

  if (isError) {
    logout()
  }

  return {
    isAuthenticated,
    userRole,
    onboardingStatus,
    logout,
    user,
    isSuperAdmin,
    isLoading,
  }
}

export default useAuth
