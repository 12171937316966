import { zodResolver } from '@hookform/resolvers/zod'
import { IconPencil } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import { useUpdateTenantAdmin } from 'src/enviroment/api/services/superAdminApi'
import { ITenant, SUBSCRIPTION_PLAN } from 'src/types/user'
import FileUpload from 'src/components/atoms/inputs/StyledFileInput'
import DropDown from 'src/components/atoms/DropDown'
import { plans } from 'src/enviroment/constants/constants'

const EditWorkspaceSchema = z.object({
  name: z.string().trim().min(3, { message: 'Name must be at least 3 characters' }),
  logo: z.any().optional(),
  plan: z.nativeEnum(SUBSCRIPTION_PLAN),
})

export type EditWorkspaceFormData = z.infer<typeof EditWorkspaceSchema>

interface Props {
  workspace: ITenant
}

type LogoState = {
  previewFile: File | null
  isDeleted: boolean
}

const EditWorkspaceDialog = ({ workspace }: Props) => {
  const defaultValues: EditWorkspaceFormData = {
    name: workspace.name,
    plan: workspace.plan,
  }

  const [opened, setOpened] = useState(false)
  const [logoState, setLogoState] = useState<LogoState>({
    previewFile: null,
    isDeleted: false,
  })

  const handleClose = useCallback(() => {
    setOpened(false)
    setLogoState({
      previewFile: null,
      isDeleted: false,
    })
    form.reset(defaultValues)
  }, [])

  const form = useForm<EditWorkspaceFormData>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: zodResolver(EditWorkspaceSchema),
  })

  const { mutateAsync: updateTenant } = useUpdateTenantAdmin()

  const handleSubmit: SubmitHandler<EditWorkspaceFormData> = useCallback(
    async (data) => {
      try {
        const basePayload = {
          id: workspace.id,
          name: data.name,
          plan: data.plan,
        }

        const updatePayload = {
          ...basePayload,
          ...(logoState.previewFile ? { logo: logoState.previewFile } : logoState.isDeleted ? { logo: null } : {}),
        }

        await updateTenant(updatePayload)
        handleClose()
      } catch (error) {
        console.error('Failed to update workspace:', error)
      }
    },
    [updateTenant, workspace.id, logoState, handleClose],
  )

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    setLogoState({
      previewFile: file || null,
      isDeleted: false,
    })
  }

  const handleClearFile = () => {
    setLogoState({
      previewFile: null,
      isDeleted: true,
    })
  }

  const displayLogoUrl = logoState.isDeleted
    ? undefined
    : logoState.previewFile
    ? URL.createObjectURL(logoState.previewFile)
    : workspace.logoUrl

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <button className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500 hover:text-white active:scale-95">
          <IconPencil size={24} />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="xl">Edit Workspace</Typography>
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <div className="flex gap-6">
            <div className="w-1/3">
              <Typography variant="sm-medium" element="label" className="mb-2 block text-gray-50">
                Logo
              </Typography>
              <FileUpload
                logoUrl={displayLogoUrl}
                disabled={form.formState.isSubmitting}
                accept="image/*"
                onChange={handleFileChange}
                onClear={handleClearFile}
              />
            </div>
            <div className="w-2/3 space-y-4">
              <Input
                label="Workspace Name"
                placeholder="Enter workspace name"
                errorMessage={form.formState.errors.name?.message}
                disabled={form.formState.isSubmitting}
                {...form.register('name')}
              />
              <Controller
                name="plan"
                control={form.control}
                render={({ field }) => (
                  <DropDown
                    label="Change plan"
                    options={plans}
                    value={field.value}
                    errorMessage={form.formState.errors.plan?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>

          <DialogFooter className="sm:justify-center sm:space-x-6">
            <SecondaryButton
              type="button"
              onClick={handleClose}
              disabled={form.formState.isSubmitting}
              className="w-40"
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton type="submit" isLoading={form.formState.isSubmitting} className="w-40">
              Save Changes
            </PrimaryButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditWorkspaceDialog
