import { UserRole } from './user'

export type ProtectedRoute =
  | typeof ROUTES.PROJECTS
  | typeof ROUTES.ATLAS
  | typeof ROUTES.TEAM
  | typeof ROUTES.PROJECT_DETAILS

export const ROUTES = {
  // Public routes
  HOME: '/',
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/auth/sign-up',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_PASSWORD_SENT: '/auth/forgot-password-sent',
  SET_NEW_PASSWORD: '/auth/set-new-password',
  PERSONAL_INFO: '/auth/personal-info',
  REQUEST_SENT: '/auth/request-sent',

  // Protected routes
  DASHBOARD: '/dashboard',
  PROJECTS: '/dashboard/projects',
  PROJECT_DETAILS: '/dashboard/project/:id',
  ATLAS: '/dashboard/atlas',
  PROFILE: '/dashboard/profile',
  TEAM: '/dashboard/team',
  SUPER_ADMIN: '/dashboard/superadmin',
  LOGOUT: '/auth/logout',
  LEGAL: '/legal',
} as const

export const ROLE_PERMISSIONS: Record<UserRole, string[]> = {
  ADMIN: [
    ROUTES.PROJECTS,
    ROUTES.PROJECT_DETAILS,
    ROUTES.ATLAS,
    ROUTES.SUPER_ADMIN,
    ROUTES.TEAM,
    ROUTES.LOGOUT,
    ROUTES.PROFILE,
    ROUTES.LEGAL,
  ],
  CONTRIBUTOR: [
    ROUTES.PROJECTS,
    ROUTES.SUPER_ADMIN,
    ROUTES.PROJECT_DETAILS,
    ROUTES.ATLAS,
    ROUTES.LOGOUT,
    ROUTES.PROFILE,
    ROUTES.LEGAL,
  ],
  VIEWER: [
    ROUTES.PROJECTS,
    ROUTES.SUPER_ADMIN,
    ROUTES.PROJECT_DETAILS,
    ROUTES.SUPER_ADMIN,
    ROUTES.LOGOUT,
    ROUTES.ATLAS,
    ROUTES.PROFILE,
    ROUTES.LEGAL,
  ],
}
