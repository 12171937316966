import { Link, useRouteError } from 'react-router'
import { useState } from 'react'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import Typography from 'src/components/atoms/Typography'
import SupportDialog from 'src/components/organisms/SupportDialog'
interface RouterError {
  status?: number
  statusText?: string
  message?: string
}

export default function ErrorPage() {
  const error = useRouteError() as RouterError
  const [isSupportOpen, setIsSupportOpen] = useState(false)

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center gap-4 text-left text-gray-50">
      <Typography variant="xl">{error?.message}</Typography>
      <Typography variant="xl">
        Oops, something went wrong. Please try again later or contact support if the problem persists.
      </Typography>
      {/* <Typography variant="xl">{error?.message || 'An unexpected error occurred. Please try again later.'}</Typography> */}
      <div className="flex gap-4">
        <SecondaryButton asChild>
          <Link to="/">Return to Home</Link>
        </SecondaryButton>
        <SecondaryButton onClick={() => setIsSupportOpen(true)}>Contact Support</SecondaryButton>
      </div>
      <SupportDialog isOpen={isSupportOpen} onClose={() => setIsSupportOpen(false)} />
    </div>
  )
}
