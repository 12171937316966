import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { client } from '../axios'
import { queryTool } from './superAdminApi'
import { useTenantStore } from 'src/enviroment/store/tenentStore'

export const StagesQueryKeys = {
  Stages: 'stages',
} as const

interface Stage {
  id: string
  name: string
  similarity?: number
}

interface StageSearchParams {
  query?: string
}

export function useSearchStages(params: StageSearchParams = {}) {
  const { query = '' } = params

  return useQuery({
    queryKey: [StagesQueryKeys.Stages, query],
    queryFn: async () => {
      const response = await client.get<Stage[]>('/stages', {
        params: {
          query: queryTool(query),
        },
      })
      return response.data
    },
  })
}

export function useGetStagesFromMyProject() {
  const tenantId = useTenantStore((state) => state.currentTenant?.id)

  return useQuery({
    queryKey: [StagesQueryKeys.Stages],
    queryFn: async () => {
      const response = await client.get<Stage[]>(`/tenant/${tenantId}/stages:my-projects`, {})
      return response.data
    },
  })
}

export function useCreateStage() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (name: string) => {
      const response = await client.post<Stage>('/stages', { name })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StagesQueryKeys.Stages] })
    },
  })
}

export function useDeleteStageTag() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (stageId: string) => {
      await client.delete(`/stages/${stageId}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StagesQueryKeys.Stages] })
    },
  })
}
