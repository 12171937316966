import { IconSearch } from '@tabler/icons-react'
import { Input } from '../../components/atoms/inputs/Input'
import AddMemberDialog from '../../components/organisms/dialogs/AddMemberDialog'
import { useColumns } from '../members/columns'
import DataTable from '../members/data-table'
import { IMember } from 'src/types/user'
import Typography from 'src/components/atoms/Typography'
import CompanyLogoUpload from 'src/components/organisms/CompanyLogoUpload'
import { getCurrentPlanLabel, getCurrentRoleLabel } from 'src/enviroment/lib/utils'
import { useTenantStore } from 'src/enviroment/store/tenentStore'

interface RAMOMembersTemplateProps {
  // Workspace props
  logoPreview: string | null
  onLogoSelect: (file: File) => Promise<void>
  onLogoRemove: () => void
  // Members list props
  members: IMember[]
  totalMembersLimit?: number
  isLoading: boolean
  isFetchingNextPage: boolean
  hasNextPage?: boolean
  onFetchNextPage?: () => void
  searchTerm: string
  onSearch: (value: string) => void
  className?: string
}

const RAMOMembersTemplate = ({
  logoPreview,
  onLogoSelect,
  onLogoRemove,
  members,
  totalMembersLimit = 10,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onFetchNextPage,
  searchTerm,
  onSearch,
  className = '',
}: RAMOMembersTemplateProps) => {
  const columns = useColumns()
  const currentTenant = useTenantStore((state) => state.currentTenant)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value)
  }

  return (
    <div className="m-6 space-y-8">
      {/* Workspace Information Section */}
      <div className="rounded-lg bg-gray-800 p-8">
        <Typography variant="xl" className="mb-8 text-light-green-50">
          Workspace Information
        </Typography>

        <div className="space-y-8">
          <div className="flex items-start space-x-8">
            <CompanyLogoUpload
              currentLogo={logoPreview}
              spaceName={currentTenant.name}
              onLogoSelect={onLogoSelect}
              onLogoRemove={onLogoRemove}
            />
          </div>

          <div className="grid grid-cols-2 gap-x-8 gap-y-6">
            <div>
              <Typography variant="sm-medium" className="text-gray-100">
                Name
              </Typography>
              <Typography variant="base" className="text-light-green-50">
                {currentTenant.name || 'Not provided'}
              </Typography>
            </div>
            <div>
              <Typography variant="sm-medium" className="text-gray-100">
                Plan
              </Typography>
              <Typography variant="base" className="text-light-green-50">
                {getCurrentPlanLabel(currentTenant.plan) || 'Not provided'}
              </Typography>
            </div>

            <div>
              <Typography variant="sm-medium" className="text-gray-100">
                Role
              </Typography>
              <Typography variant="base" className="text-light-green-50">
                {getCurrentRoleLabel(currentTenant.tenantMemberAccess.role)}
              </Typography>
            </div>

            <div>
              <Typography variant="sm-medium" className="text-gray-100">
                Workspace ID
              </Typography>
              <Typography variant="base" className="text-light-green-50">
                {currentTenant.id || 'Not provided'}
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {/* Members List Section */}
      <div className={`flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8 ${className}`}>
        <div className="flex items-center">
          <Typography variant="xl" className="w-2/4 text-light-green-50">
            {currentTenant.name} Members
          </Typography>
          <div className="flex w-2/3 items-center gap-6">
            <Input
              containerClassName="w-full"
              icon={<IconSearch />}
              placeholder="Search by name, email, company..."
              onChange={handleSearch}
              value={searchTerm}
            />
            <AddMemberDialog />
          </div>
        </div>
        <div className="flex max-h-[600px] flex-col gap-6 overflow-y-auto">
          <span className="text-white">
            {isLoading ? 'Loading members...' : `Showing ${members.length} of ${totalMembersLimit} members`}
          </span>
          <DataTable
            columns={columns}
            data={members}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            onLoadMore={onFetchNextPage}
          />
        </div>
      </div>
    </div>
  )
}

export default RAMOMembersTemplate
