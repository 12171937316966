import { zodResolver } from '@hookform/resolvers/zod'
import { IconFileUpload, IconHelpCircle } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import FileDropzone from '../../molecules/FileDropZone'
import { useCreateProjectMutation } from 'src/enviroment/api/services/projectsApi'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import SupportDialog from '../../organisms/SupportDialog'

const defaultValues: CreateProjectFormData = {
  title: '',
  files: [],
}

const AddMemberSchema = z.object({
  title: z.string().trim().min(1, 'Project name is required').max(100, 'Project name must be less than 100 characters'),
  files: z.array(z.instanceof(File)).min(1, 'At least one file is required').max(10, 'Maximum 10 files allowed'),
})

type CreateProjectFormData = z.infer<typeof AddMemberSchema>

const CreateProjectDialog = () => {
  const [opened, setOpened] = useState<boolean>(false)
  const [supportDialog, setSupportDialogOpen] = useState<boolean>(false)

  const [files, setFiles] = useState<File[]>([])
  const createProject = useCreateProjectMutation()

  const form = useForm<CreateProjectFormData>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(AddMemberSchema),
  })

  const handleSubmit: SubmitHandler<CreateProjectFormData> = useCallback(async ({ title, files }) => {
    try {
      await createProject.mutateAsync({
        name: title,
        files: files,
      })
      form.reset()
      setFiles([])
      setOpened(false)
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <PrimaryButton
          className="bg-gray-50 text-gray-900 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-500"
          size="sm"
          leftIcon={<IconFileUpload />}
        >
          Upload file
        </PrimaryButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create project</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            errorMessage={form.formState.errors.title?.message}
            label="Project Name"
            placeholder="Text"
            {...form.register('title')}
          />
          <FileDropzone
            files={files}
            onFilesChange={(newFiles) => {
              setFiles(newFiles)
              form.setValue('files', newFiles, {
                shouldValidate: true,
              })
            }}
          />
          {form.formState.errors.files && <ErrorMessage message={form.formState.errors.files?.message} />}
        </div>
        <DialogFooter className="sm:justify-between sm:space-x-6">
          <button
            type="button"
            onClick={() => {
              setSupportDialogOpen(!supportDialog)
              setOpened(false)
            }}
            className="flex flex-row items-center space-x-2 text-light-green-50"
          >
            <IconHelpCircle />
            <span>Support</span>
          </button>
          <PrimaryButton
            onClick={form.handleSubmit(handleSubmit)}
            isLoading={form.formState.isSubmitting}
            className="w-40"
            type="submit"
          >
            Create
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
      <SupportDialog isOpen={supportDialog} onClose={() => setSupportDialogOpen(!supportDialog)} />
    </Dialog>
  )
}

export default CreateProjectDialog
