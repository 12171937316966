import { FC, useState } from 'react'
import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet-async'

import { useProjectsQuery } from 'src/enviroment/api/services/projectsApi'
import ProjectsTemplate from 'src/templates/dashboard/MyProjectsTemplate'
import { Project } from 'src/types/projects'
import { Types, useGetLocationsMyProjects, useGetTypesMyProjects } from 'src/enviroment/api/services/typesApi'
import { useGetStagesFromMyProject } from 'src/enviroment/api/services/stageApi'

const ProjectsPage: FC = () => {
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedTypesIds, setSelectedTypesIds] = useState<string[]>([])
  const [selectedStagesIds, setSelectedStagesIds] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])

  const { data: types } = useGetTypesMyProjects()
  const { data: stages } = useGetStagesFromMyProject()
  const { data: locations } = useGetLocationsMyProjects()

  const { data, isLoading, error, isFetching } = useProjectsQuery({
    searchTerm,
    typeIds: selectedTypesIds,
    stageIds: selectedStagesIds,
    location: selectedLocations,
  })
  const handleOpenProject = (project: Project): void => {
    navigate(`/dashboard/project/${project.id}`)
  }

  // Safely handle the data flattening with type checking
  const flattenedProjects: Project[] =
    data?.pages?.reduce<Project[]>((acc, page) => {
      if (Array.isArray(page)) {
        return [...acc, ...page]
      }
      return acc
    }, []) || []

  const formattedLocations = locations?.map((location) => ({
    id: location,
    name: location,
  })) as unknown as Types[]

  return (
    <>
      <Helmet>
        <title>RAMO - My Projects</title>
      </Helmet>

      <ProjectsTemplate
        projects={flattenedProjects}
        isLoading={isLoading}
        isFetching={isFetching}
        searchTerm={searchTerm}
        selectedTypesIds={selectedTypesIds}
        types={types}
        stages={stages}
        locations={formattedLocations}
        selectedLocations={selectedLocations}
        setSelectedLocations={setSelectedLocations}
        setSelectedTypesIds={setSelectedTypesIds}
        selectedStagesIds={selectedStagesIds}
        setSelectedStagesIds={setSelectedStagesIds}
        setSearchTerm={(e) => setSearchTerm(e.target.value)}
        error={error}
        onOpenProject={handleOpenProject}
      />
    </>
  )
}

export default ProjectsPage
