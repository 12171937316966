import React from 'react'
import { useNavigate } from 'react-router'
import Typography from 'src/components/atoms/Typography'

const Footer: React.FC = () => {
  const navigate = useNavigate()
  const currentYear = new Date().getFullYear()

  const handleNavigation = (section: string) => {
    navigate(`/legal?section=${section}`)
  }

  return (
    <footer className="border-t border-gray-600 bg-gray-900 px-4 py-2">
      <div className="flex items-center justify-between text-light-green-50">
        <Typography variant="xs">© {currentYear} RAMO. All rights reserved.</Typography>

        <div className="flex gap-4">
          <button onClick={() => handleNavigation('privacy')} className="hover:text-light-green-100">
            <Typography variant="xs">Privacy Notice</Typography>
          </button>

          <button onClick={() => handleNavigation('terms')} className="hover:text-light-green-100">
            <Typography variant="xs">Terms and Conditions</Typography>
          </button>

          <button onClick={() => handleNavigation('contacts')} className="hover:text-light-green-100">
            <Typography variant="xs">Contacts</Typography>
          </button>
        </div>
      </div>
    </footer>
  )
}

export default Footer
