import { zodResolver } from '@hookform/resolvers/zod'
import { IconPlus } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import { useAddNewProject } from 'src/enviroment/api/services/superAdminApi'
import Typography from '../../atoms/Typography'
import { Checkbox } from 'src/components/atoms/buttons/CheckBoxButton'
import TagSelector from '../TypeTagSelector'
import StageSelector from '../StageSelector'

const defaultValues: AddProjectFormData = {
  name: '',
  location: '',
  mapUrl: '',
  totalArea: 0,
  typeIds: [],
  stageId: null,
  isPublic: false,
}

const AddProjectSchema = z.object({
  name: z.string().trim().min(3, { message: 'Name must be at least 3 characters' }),
  location: z.string().trim().min(3, { message: 'Country must be at least 3 characters' }),
  mapUrl: z.string().url({ message: 'Invalid URL' }),
  totalArea: z.number({ coerce: true }).positive({ message: 'Area size must be a positive number' }),
  typeIds: z.array(z.string()).default([]),
  stageId: z.string().nullable(),
  isPublic: z.boolean().default(false),
})

export type AddProjectFormData = z.infer<typeof AddProjectSchema>

const AddProjectDialog = () => {
  const [opened, setOpened] = useState(false)
  const handleClose = useCallback(() => setOpened(false), [])

  const form = useForm<AddProjectFormData>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: zodResolver(AddProjectSchema),
  })

  const { mutateAsync: createNewProject } = useAddNewProject()

  const handleSubmit: SubmitHandler<AddProjectFormData> = useCallback(async (data) => {
    await createNewProject(data)
    setOpened(false)
    form.reset()
  }, [])

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <SecondaryButton className="flex-shrink-0" leftIcon={<IconPlus />}>
          Add Project
        </SecondaryButton>
      </DialogTrigger>
      <DialogContent aria-description="add-project-dialog">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="xl">Add Project</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <Input
              type="text"
              disabled={form.formState.isSubmitting}
              autoComplete="off"
              label="Name of Project"
              placeholder="Name of Project"
              {...form.register('name')}
            />
          </div>
          <div>
            <Input
              type="text"
              disabled={form.formState.isSubmitting}
              autoComplete="off"
              label="Location"
              placeholder="Angola"
              {...form.register('location')}
            />
          </div>
          <div>
            <Input
              type="text"
              disabled={form.formState.isSubmitting}
              autoComplete="off"
              label="Map Link"
              placeholder="https://felt.com/map/Deforestation-Risk-G2K46e8kT9CWoinzt7rxWfB?loc=-11.262,17.919,5.5z"
              {...form.register('mapUrl')}
            />
          </div>
          <div>
            <Input
              type="number"
              disabled={form.formState.isSubmitting}
              autoComplete="off"
              label="Area Size"
              placeholder="9339.339"
              {...form.register('totalArea')}
            />
          </div>
          <div className="col-span-2">
            <TagSelector
              onTypesChange={(typeIds) => form.setValue('typeIds', typeIds)}
              label="Types"
              placeholder="Search types"
            />
          </div>
          <div className="col-span-2">
            <StageSelector
              onStageChange={(stageId) => form.setValue('stageId', stageId)}
              label="Stage"
              placeholder="Search stages"
            />
          </div>
          <div className="col-span-2 flex items-center space-x-2">
            <div className="flex items-center">
              <Checkbox
                id="isPublic"
                disabled={form.formState.isSubmitting}
                {...form.register('isPublic')}
                label="Make project as public"
              />
            </div>
          </div>
        </div>
        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton isLoading={form.formState.isSubmitting} onClick={handleClose} className="w-40">
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={form.handleSubmit(handleSubmit)}
            isLoading={form.formState.isSubmitting}
            className="w-40"
          >
            Create
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddProjectDialog
