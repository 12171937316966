import { zodResolver } from '@hookform/resolvers/zod'
import { IconPencil } from '@tabler/icons-react'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import { useUpdateGlobalProjectMutation } from 'src/enviroment/api/services/superAdminApi'
import Typography from '../../atoms/Typography'
import { Project } from 'src/types/projects'
import { Checkbox } from 'src/components/atoms/buttons/CheckBoxButton'
import TagSelector from '../TypeTagSelector'
import StageSelector from '../StageSelector'

const AddProjectSchema = z.object({
  name: z.string().trim().min(3, { message: 'Name must be at least 3 characters' }),
  location: z.string().trim().min(3, { message: 'Country must be at least 3 characters' }),
  totalArea: z.number({ coerce: true }).positive({ message: 'Area size must be a positive number' }),
  typeIds: z.array(z.string()).default([]).optional(),
  stageId: z.string().nullable().optional(),
  isPublic: z.boolean(),
})

export type AddProjectFormData = z.infer<typeof AddProjectSchema>

interface Props {
  project: Project
}

const EditProjectDialog = ({ project }: Props) => {
  const [opened, setOpened] = useState(false)
  const { mutateAsync: updateGlobalProject } = useUpdateGlobalProjectMutation()
  const handleClose = useCallback(() => setOpened(false), [])
  const form = useForm<AddProjectFormData>({
    mode: 'all',
    defaultValues: {
      name: project.name,
      location: project.location,
      isPublic: project.isPublic,
      typeIds: project.types.map((tag) => tag.id),
      // typeIds
      // stageId
      stageId: project.stage?.id || null,
      totalArea: project.totalAreaHectares,
    },
    reValidateMode: 'onChange',
    resolver: zodResolver(AddProjectSchema),
  })

  useEffect(() => {
    form.reset({
      name: project.name,
      location: project.location,
      isPublic: project.isPublic,
      totalArea: project.totalAreaHectares,
    })
  }, [project])

  const handleSubmit: SubmitHandler<AddProjectFormData> = useCallback(
    async (data) => {
      await updateGlobalProject({ ...data, id: project.id })
      setOpened(false)
      form.reset()
    },
    [form, project.id, updateGlobalProject],
  )

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      form.setValue('isPublic', checked, { shouldValidate: true })
    },
    [form],
  )

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500 hover:text-white active:scale-95">
        <IconPencil size={24} />
      </DialogTrigger>
      <DialogContent aria-description="add-project-dialog">
        <DialogHeader aria-describedby="add-project-dialog">
          <DialogTitle>
            <Typography variant="xl">Edit Project</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Name of Project"
            placeholder="Name of Project"
            {...form.register('name')}
          />
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Location"
            placeholder="Angola"
            {...form.register('location')}
          />
          <Input
            type="number"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Area Size"
            placeholder="9339.339"
            {...form.register('totalArea')}
          />
          <div className="col-span-2">
            <TagSelector
              defaultValue={project.types}
              onTypesChange={(typesIds) => form.setValue('typeIds', typesIds)}
              label="Types"
              placeholder="Search types"
            />
          </div>
          <div className="col-span-2">
            <StageSelector
              defaultValue={project.stage}
              onStageChange={(stageId) => form.setValue('stageId', stageId)}
              label="Stage"
              placeholder="Search stage"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="isPublic"
              disabled={form.formState.isSubmitting}
              onCheckedChange={handleCheckboxChange}
              checked={form.watch('isPublic')}
              label="Make project as public"
            />
          </div>
        </div>
        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton isLoading={form.formState.isSubmitting} onClick={handleClose} className="w-40">
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={form.handleSubmit(handleSubmit)}
            isLoading={form.formState.isSubmitting}
            className="w-40"
          >
            Save
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditProjectDialog
