// createPopupContent.ts
import { Types } from 'src/enviroment/api/services/typesApi'
import { formatArea } from 'src/enviroment/lib/utils'
import { Project } from 'src/types/projects'

interface DetailProps {
  icon: string
  text: string | undefined
  className?: string
}

const LOCATION_ICON = `<svg width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 11.5a2.5 2.5 0 1 0 0 -5a2.5 2.5 0 0 0 0 5z" /><path d="M12 2a8 8 0 0 0 -8 8c0 1.892 .402 3.13 1.5 4.5l4.5 5.5a3 3 0 0 0 4 0l4.5 -5.5c1.098 -1.37 1.5 -2.608 1.5 -4.5a8 8 0 0 0 -8 -8z" /></svg>`

const RULER_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-ruler h-4 w-4"><path d="M5 4h14a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-7a1 1 0 0 0 -1 1v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1"></path><path d="M4 8l2 0"></path><path d="M4 12l3 0"></path><path d="M4 16l2 0"></path><path d="M8 4l0 2"></path><path d="M12 4l0 3"></path><path d="M16 4l0 2"></path></svg>`

const STAGE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-chart-funnel h-4 w-4"><path d="M4.387 3h15.226a1 1 0 0 1 .948 1.316l-5.105 15.316a2 2 0 0 1 -1.898 1.368h-3.116a2 2 0 0 1 -1.898 -1.368l-5.104 -15.316a1 1 0 0 1 .947 -1.316"></path><path d="M5 9h14"></path><path d="M7 15h10"></path></svg>`

const TYPES_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-tree h-4 w-4"><path d="M12 13l-2 -2"></path><path d="M12 12l2 -2"></path><path d="M12 21v-13"></path><path d="M9.824 16a3 3 0 0 1 -2.743 -3.69a3 3 0 0 1 .304 -4.833a3 3 0 0 1 4.615 -3.707a3 3 0 0 1 4.614 3.707a3 3 0 0 1 .305 4.833a3 3 0 0 1 -2.919 3.695h-4z"></path></svg>`

const createDetailElement = ({
  icon,
  text,
  className = 'flex items-center gap-2 text-light-green-50 text-sm',
}: DetailProps): HTMLDivElement | null => {
  if (!text) return null

  const div = document.createElement('div')
  div.className = className
  div.innerHTML = icon

  const span = document.createElement('span')
  span.className = 'text-sm'
  span.textContent = text
  div.appendChild(span)

  return div
}

export const createPopupContent = (project: Project): HTMLDivElement => {
  const container = document.createElement('div')
  container.className = 'flex flex-col gap-4 p-2'

  // Title
  const title = document.createElement('div')
  title.className = 'font-bold text-base text-light-green-50'
  title.textContent = project.name

  // Image
  const image = document.createElement('img')
  image.src = project.thumbnailUrl || '/src/assets/img/project_placeholder.png'
  image.className = 'w-48 h-32 object-cover rounded'
  image.alt = project.name

  // Details container
  const detailsContainer = document.createElement('div')
  detailsContainer.className = 'grid grid-cols-2 gap-4'

  // Left column (Location and Area)
  const leftColumn = document.createElement('div')
  leftColumn.className = 'flex flex-col gap-4'

  // Create location and area elements
  const locationElement = createDetailElement({ icon: LOCATION_ICON, text: project.location })
  const areaElement = createDetailElement({ icon: RULER_ICON, text: formatArea(project.totalAreaHectares) })

  if (locationElement) leftColumn.appendChild(locationElement)
  if (areaElement) leftColumn.appendChild(areaElement)

  // Right column (Stage and Types)
  const rightColumn = document.createElement('div')
  rightColumn.className = 'flex flex-col gap-4'

  // Create stage and types elements
  const stageElement = createDetailElement({
    icon: STAGE_ICON,
    text: project.stage
      ? (() => {
          try {
            return JSON.parse(project.stage as any).name
          } catch (e) {
            console.error('Error parsing stage:', e)
            return undefined
          }
        })()
      : undefined,
  })

  const typesElement = createDetailElement({
    icon: TYPES_ICON,
    text: project.types
      ? (() => {
          try {
            return JSON.parse(project.types as any)
              .map((type: Types) => type?.name)
              .filter(Boolean)
              .join(', ')
          } catch (e) {
            console.error('Error parsing types:', e)
            return undefined
          }
        })()
      : undefined,
  })

  if (stageElement) rightColumn.appendChild(stageElement)
  if (typesElement) rightColumn.appendChild(typesElement)

  // Append columns to details container
  detailsContainer.appendChild(leftColumn)
  detailsContainer.appendChild(rightColumn)

  // Append main elements to container
  container.appendChild(title)
  container.appendChild(image)
  container.appendChild(detailsContainer)

  return container
}
