import React, { useState } from 'react'
import { MoreVertical, Download, X } from 'lucide-react'
import { Layer } from '@feltmaps/js-sdk'
import Typography from '../atoms/Typography'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import { SUBSCRIPTION_PLAN } from 'src/types/user'
import SupportDialog from './SupportDialog'

interface LayerListProps {
  layers: Layer[]
  onExport: (layer: Layer) => void
}

const LayerList = ({ layers, onExport }: LayerListProps) => {
  const [isListVisible, setIsListVisible] = useState(false)
  const [openMenuId, setOpenMenuId] = useState<string | null>(null)
  const [isSupportOpen, setIsSupportOpen] = useState<boolean>(false)
  const isFreePlan = useTenantStore((state) => state.currentTenant.plan) === SUBSCRIPTION_PLAN.FREE

  const handleMenuClick = (layerId: string) => {
    setOpenMenuId(openMenuId === layerId ? null : layerId)
  }

  const handleExport = (layer: Layer) => {
    onExport(layer)
    setOpenMenuId(null)
    setIsListVisible(false)
  }

  const handleExportButtonClick = () => {
    if (isFreePlan) {
      setIsSupportOpen(true)
    } else {
      setIsListVisible(true)
    }
  }

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (!target.closest('.layers-container')) {
        setOpenMenuId(null)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  return (
    <>
      <div className="absolute right-4 top-5 z-50">
        {!isListVisible ? (
          <div className="min-w-[255px] rounded-[8px] backdrop-blur">
            <div className="rounded-[8px] p-2">
              <button
                onClick={handleExportButtonClick}
                className="flex w-full flex-row items-center justify-center rounded-[6px] border border-gray-500 bg-[rgb(21,20,18)] px-2 py-2 text-white transition-colors duration-200 hover:bg-[rgb(64,63,59)]"
              >
                <div className="flex flex-row items-center justify-center">
                  <Download className="mr-1 h-3 w-3" />

                  <span className="text-sm font-semibold">Export layer</span>
                  {isFreePlan && (
                    <div className="ml-2 items-center justify-center rounded-full bg-light-green-400 px-2 py-1">
                      <Typography variant="sm-medium">Premium</Typography>
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div className="layers-container min-w-[300px] rounded-[8px] p-2 backdrop-blur">
            <div className="rounded-[8px] bg-gray-900 p-3">
              <div className="flex items-center justify-between border-b border-gray-700 px-3 py-2">
                <Typography variant="sm" className="font-medium text-light-green-50">
                  Select layer
                </Typography>
                <button
                  onClick={() => {
                    setIsListVisible(false)
                    setOpenMenuId(null)
                  }}
                  className="rounded-full p-1 hover:bg-gray-800"
                >
                  <X className="h-4 w-4 text-light-green-50" />
                </button>
              </div>
              <div className="max-h-[calc(100vh-160px)] overflow-y-auto">
                {layers.map((layer, index) => (
                  <div
                    key={layer.id}
                    className="group flex items-center justify-between border-b border-gray-700 px-3 py-2 last:border-0"
                  >
                    <div className="flex-1 truncate pr-2">
                      <Typography variant="sm" className="font-medium text-light-green-50">
                        {layer.name}
                      </Typography>
                    </div>

                    <div className="relative">
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          handleMenuClick(layer.id)
                        }}
                        className="rounded-full p-1 hover:bg-gray-800 focus:outline-none"
                      >
                        <MoreVertical className="h-4 w-4 text-light-green-50 group-hover:text-light-green-100" />
                      </button>

                      {openMenuId === layer.id && (
                        <div
                          className={`absolute ${
                            index === layers.length - 1 ? 'bottom-full mb-1' : 'top-full mt-1'
                          } right-0 z-50 w-32 rounded-md bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5`}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation()
                              handleExport(layer)
                            }}
                            className="flex w-full items-center px-3 py-1.5 text-sm text-light-green-50 hover:bg-gray-800"
                          >
                            <Download className="mr-2 h-4 w-4" />
                            Export
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <SupportDialog isOpen={isSupportOpen} onClose={() => setIsSupportOpen(false)} />
    </>
  )
}

export default LayerList
