import { FC, useMemo } from 'react'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/molecules/Table'
import { format } from 'date-fns'
import { Image as ImageIcon, Loader, RefreshCw } from 'lucide-react'
import { Project } from 'src/types/projects'
import DeleteProjectDialog from 'src/components/organisms/dialogs/DeleteProjectDialog'
import EditProjectDialog from 'src/components/organisms/dialogs/EditProjectDialog'
import EditProjectAccess from 'src/components/organisms/dialogs/EditProjectAccessDialog'

interface ProjectTableProps {
  projects: Project[]
  isLoading: boolean
  onRefreshThumbnail?: (projectId: string) => void
}

export const ProjectTable: FC<ProjectTableProps> = ({ projects, isLoading, onRefreshThumbnail }) => {
  const columns = useMemo<ColumnDef<Project>[]>(
    () => [
      {
        accessorKey: 'thumbnailUrl',
        header: 'Thumbnail',
        cell: ({ row }) => (
          <div className="relative h-16 w-24">
            {row.original.thumbnailUrl ? (
              <>
                <img
                  src={row.original.thumbnailUrl}
                  alt={`${row.original.name} thumbnail`}
                  className="h-full w-full rounded object-cover"
                  onError={(e) => {
                    e.currentTarget.style.display = 'none'
                    e.currentTarget.nextElementSibling?.classList.remove('hidden')
                  }}
                />
                <button
                  onClick={() => onRefreshThumbnail?.(row.original.id)}
                  className="absolute right-1 top-1 rounded-full bg-white/80 p-1 hover:bg-white focus:outline-none focus:ring-2 focus:ring-primary"
                  title="Refresh thumbnail"
                >
                  <RefreshCw className="h-4 w-4 text-gray-600" />
                </button>
              </>
            ) : null}
            <div
              className={`absolute inset-0 flex items-center justify-center rounded bg-secondary ${
                row.original.thumbnailUrl ? 'hidden' : ''
              }`}
            >
              <ImageIcon className="h-6 w-6 text-muted-foreground" />
            </div>
          </div>
        ),
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: 'ID',
      },
      {
        id: 'isPublic',
        accessorKey: 'isPublic',
        header: 'Public',
        cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
      },
      {
        id: 'name',
        header: 'Project Name',
        cell: ({
          row: {
            original: { name },
          },
        }) => <span className="font-bold">{name}</span>,
      },
      {
        accessorKey: 'location',
        header: 'Country',
      },
      {
        accessorKey: 'totalAreaHectares',
        header: 'Total Area (ha)',
        cell: ({ getValue }) => getValue<number>().toLocaleString(),
      },
      {
        accessorKey: 'createdAt',
        header: 'Created Date',
        cell: ({ getValue }) => format(new Date(getValue() as string), 'MMM dd, yyyy'),
      },
      {
        accessorKey: 'updatedAt',
        header: 'Last Updated',
        cell: ({ getValue }) => format(new Date(getValue() as string), 'MMM dd, yyyy'),
      },
      {
        id: 'actions',
        header: 'Actions',
        cell: ({ row }) => (
          <div className="flex">
            <EditProjectDialog project={row.original} />
            <EditProjectAccess project={row.original} />
            <DeleteProjectDialog project={row.original} requireConfirmation />
          </div>
        ),
      },
    ],
    [onRefreshThumbnail],
  )

  const table = useReactTable({
    data: projects,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading) {
    return (
      <div className="flex h-48 items-center justify-center">
        <Loader className="h-8 w-8 animate-spin text-primary" />
      </div>
    )
  }

  return (
    <div className="rounded-md">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))}
          {projects.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length} className="text-center">
                No projects found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
