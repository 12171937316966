import React from 'react'
import Typography from 'src/components/atoms/Typography'
import { IconChartFunnel, IconMapPin, IconRuler, IconTree } from '@tabler/icons-react'
import { Project } from 'src/types/projects'
import { SecondaryButton } from '../atoms/buttons/SecondaryButton'
import useAuth from 'src/enviroment/hooks/useAuth'
import Placeholder from 'src/assets/img/project_placeholder.png'
import { UserRole } from 'src/types/user'
import { formatArea } from 'src/enviroment/lib/utils'

interface ProjectCardProps {
  project: Project
  onOpen?: (project: Project) => void
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, onOpen }) => {
  const { userRole } = useAuth()
  const isDeleteAllowed = userRole === UserRole.ADMIN || userRole === UserRole.CONTRIBUTOR

  const leftColumnDetails = [
    {
      icon: <IconMapPin className="h-4 w-4" />,
      text: project.location,
    },
    {
      icon: <IconRuler className="h-4 w-4" />,
      text: formatArea(project.totalAreaHectares),
    },
  ]

  const rightColumnDetails = [
    {
      icon: <IconChartFunnel className="h-4 w-4" />,
      text: project.stage?.name,
      show: !!project.stage,
    },
    {
      icon: <IconTree className="h-4 w-4" />,
      text: project.types.map((type) => type.name).join(', '),
      show: project.types.length > 0,
    },
  ].filter((detail) => detail.show !== false)

  return (
    <div className="relative overflow-hidden rounded-lg bg-gray-800">
      <img src={project.thumbnailUrl || Placeholder} alt={project.name} className="h-40 w-full object-cover" />
      <div className="space-y-4 p-4">
        <Typography variant="base-bold" className="text-light-green-50">
          {project.name}
        </Typography>

        <div className="flex gap-4">
          {/* Left Column */}
          <div className="flex-1 space-y-4">
            {leftColumnDetails.map((detail, index) => (
              <div key={index} className="flex items-center gap-2 text-light-green-50">
                {detail.icon}
                <Typography variant="sm">{detail.text}</Typography>
              </div>
            ))}
          </div>

          {/* Right Column */}
          <div className="flex-1 space-y-4">
            {rightColumnDetails.map((detail, index) => (
              <div key={index} className="flex items-center gap-2 text-light-green-50">
                {detail.icon}
                <Typography variant="sm" className="capitalize">
                  {detail.text}
                </Typography>
              </div>
            ))}
          </div>
        </div>

        <SecondaryButton className="w-full" onClick={() => onOpen?.(project)}>
          Open Project
        </SecondaryButton>
        {/* {isDeleteAllowed && <DeleteProjectDialog project={project} />} */}
      </div>
    </div>
  )
}

export default ProjectCard
