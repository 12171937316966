import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
  IconMapPin2,
  IconUsers,
  IconHelpCircle,
  IconLogout,
  IconUserCircle,
  IconTower,
  IconLayoutSidebarRight,
  IconWorld,
} from '@tabler/icons-react'
import Logo from '../atoms/icons/Logo'
import LogoBig from '../atoms/icons/LogoBig'

import SmallLogo from '../atoms/icons/LogoSymbol'
import SidebarSection from './SidebarSection'
import useAuth from 'src/enviroment/hooks/useAuth'
import { ROLE_PERMISSIONS, ROUTES } from 'src/types/routes'
import LogoutDialog from '../molecules/LogoutDialog'
import SupportDialog from '../organisms/SupportDialog'
import SectionHeader from '../molecules/SectionHeader'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import CompanyLogo from '../atoms/CompanyLogo'

interface SidebarProps {
  isCollapsed: boolean
  onCollapse: (collapsed: boolean) => void
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, onCollapse }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { userRole, user } = useAuth()
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false)
  const [isSupportOpen, setIsSupportOpen] = useState<boolean>(false)
  const currentTenant = useTenantStore((state) => state.currentTenant)
  const isActiveLink = (paths: string | string[]) => {
    const currentPath = location.pathname

    if (typeof paths === 'string') {
      return currentPath === paths
    }

    if (paths.includes(ROUTES.PROJECTS)) {
      return currentPath === ROUTES.PROJECTS || currentPath.startsWith('/dashboard/project/')
    }

    return paths.some((path) => currentPath === path)
  }

  const handleLogoutClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsLogoutDialogOpen(true)
  }

  const handleLogoutConfirm = async () => {
    try {
      navigate(ROUTES.LOGOUT)
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  const allWorkspaceItems = [
    {
      to: ROUTES.ATLAS,
      icon: <IconWorld className="h-5 w-5" />,
      label: 'Atlas',
      isActive: isActiveLink(ROUTES.ATLAS),
      route: ROUTES.ATLAS,
    },
    {
      to: ROUTES.PROJECTS,
      icon: <IconMapPin2 className="h-5 w-5" />,
      label: 'My Projects',
      isActive: isActiveLink([ROUTES.PROJECTS, ROUTES.PROJECT_DETAILS]),
      route: ROUTES.PROJECTS,
    },
  ]

  const baseAccountItems = [
    {
      to: ROUTES.TEAM,
      icon: <IconUsers className="h-5 w-5" />,
      label: 'Team',
      isActive: isActiveLink(ROUTES.TEAM),
      route: ROUTES.TEAM,
    },
    {
      to: ROUTES.PROFILE,
      icon: <IconUserCircle className="h-5 w-5" />,
      label: 'My Profile',
      isActive: isActiveLink(ROUTES.PROFILE),
      route: ROUTES.PROFILE,
    },
    {
      to: '#',
      icon: <IconHelpCircle className="h-5 w-5" />,
      label: 'Support',
      isActive: false,
      onClick: () => setIsSupportOpen(!isSupportOpen),
    },
    {
      to: '#',
      icon: <IconLogout className="h-5 w-5" />,
      label: 'Log Out',
      isActive: false,
      onClick: handleLogoutClick,
    },
  ]

  const allAccountItems = user?.isSuperadmin
    ? [
        ...baseAccountItems.slice(0, 2),
        {
          to: ROUTES.SUPER_ADMIN,
          icon: <IconTower className="h-5 w-5" />,
          label: 'Platform Admin',
          isActive: isActiveLink(ROUTES.SUPER_ADMIN),
          route: ROUTES.SUPER_ADMIN,
        },
        ...baseAccountItems.slice(2),
      ]
    : baseAccountItems

  const filteredWorkspaceItems = allWorkspaceItems.filter((item) =>
    item.route ? ROLE_PERMISSIONS[userRole]?.includes(item.route) : true,
  )

  const filteredAccountItems = allAccountItems.filter((item) => {
    if (item.route === ROUTES.SUPER_ADMIN) {
      return user?.isSuperadmin
    }
    return item.route ? ROLE_PERMISSIONS[userRole]?.includes(item.route) : true
  })

  return (
    <aside
      className={`fixed left-0 top-0 z-30 h-full border-r border-gray-600 bg-gray-800 text-green-50 transition-all duration-300 ${
        isCollapsed ? 'w-[88px]' : 'w-64'
      }`}
    >
      <div className="flex h-full flex-col p-4 pt-10">
        {isCollapsed ? (
          <div className="mb-5 flex justify-center">
            <SmallLogo />
          </div>
        ) : (
          <>
            {currentTenant.logoUrl ? (
              <CompanyLogo logoUrl={currentTenant.logoUrl} altText={currentTenant.name} />
            ) : (
              <div className="mb-4 flex justify-center">
                <LogoBig />
              </div>
            )}
          </>
        )}
        <SectionHeader isCollapsed={isCollapsed} />

        {filteredWorkspaceItems.length > 0 && (
          <SidebarSection isCollapsed={isCollapsed} items={filteredWorkspaceItems} title="Workspace" />
        )}

        <SidebarSection isCollapsed={isCollapsed} items={filteredAccountItems} title="Account Settings" />

        <div className="mt-auto">
          <div className="mt-auto">
            {/* Logo component */}
            <div className="mb-4 flex justify-start">{!isCollapsed && currentTenant.logoUrl && <Logo />}</div>
          </div>
          <button
            onClick={() => onCollapse(!isCollapsed)}
            className="flex w-full items-center gap-3 rounded-md p-2 text-light-green-50 hover:bg-green-50 hover:text-accent-foreground"
          >
            <IconLayoutSidebarRight className="h-5 w-5" />
            {!isCollapsed && <span>Hide Sidebar</span>}
          </button>
        </div>
      </div>
      <LogoutDialog
        isOpen={isLogoutDialogOpen}
        onClose={() => setIsLogoutDialogOpen(false)}
        onConfirm={handleLogoutConfirm}
      />
      <SupportDialog isOpen={isSupportOpen} onClose={() => setIsSupportOpen(false)} />
    </aside>
  )
}

export default Sidebar
