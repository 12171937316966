import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useForgotPassword } from 'src/enviroment/api/services/authApi'
import EmailSentPasswordTemplate from 'src/templates/auth/forgot-password/ForgotPasswordEmailSent'
import { ROUTES } from 'src/types/routes'

export default function ForgotPasswordSentPage() {
  const navigate = useNavigate()
  const email = useLocation().state?.email
  const { mutate } = useForgotPassword()
  const goBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleResend = useCallback(() => {
    mutate(email)
  }, [email])

  const handleTryAnotherEmail = useCallback(() => {
    navigate(ROUTES.FORGOT_PASSWORD)
  }, [navigate])

  return (
    <EmailSentPasswordTemplate
      handleTryAnotherEmail={handleTryAnotherEmail}
      handleResend={handleResend}
      handleGoBack={goBack}
      email={email}
    />
  )
}
