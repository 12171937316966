import * as React from 'react'

interface SvgIconProps {
  className?: string
}

const SvgIcon: React.FC<SvgIconProps> = ({ className }) => (
  <svg
    className={`${className}`}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m13 11 9-9" />
    <path d="M14.6 12.6c.8.8.9 2.1.2 3L10 22l-8-8 6.4-4.8c.9-.7 2.2-.6 3 .2Z" />
    <path d="m6.8 10.4 6.8 6.8" />
    <path d="m5 17 1.4-1.4" />
  </svg>
)

export default SvgIcon
