import { on } from 'events'
import React, { useCallback, useEffect } from 'react'

import { useNavigate } from 'react-router'
import useAuth from 'src/enviroment/hooks/useAuth'
import RequestSentTemplate from 'src/templates/auth/sign-up/RequestSentTemplate'
import { ROUTES } from 'src/types/routes'
import { ONBOARDING_STATUS } from 'src/types/user'

const RequestSentPage: React.FC = () => {
  const navigate = useNavigate()
  const { logout, isLoading, isAuthenticated, onboardingStatus } = useAuth()

  if (onboardingStatus === ONBOARDING_STATUS.DONE) {
    navigate(ROUTES.DASHBOARD)
  }

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate(ROUTES.SIGN_IN)
    }
  }, [isLoading, isAuthenticated, navigate])

  const handleGoMain = useCallback(() => {
    window.location.href = 'https://www.ramo.earth/'
  }, [])

  return <RequestSentTemplate handleLogout={logout} handleGoMain={handleGoMain} />
}

export default RequestSentPage
