import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useSearchParams } from 'react-router'
import Typography from 'src/components/atoms/Typography'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import { IconArrowLeft, IconMenu2 } from '@tabler/icons-react'
import { cn } from 'src/enviroment/lib/utils'

const LegalPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const [activeSection, setActiveSection] = useState<string>('privacy')
  const privacyRef = useRef<HTMLDivElement>(null)
  const termsRef = useRef<HTMLDivElement>(null)
  const contactsRef = useRef<HTMLDivElement>(null)

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>, section: string) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
    setActiveSection(section)
    setIsMobileMenuOpen(false)
  }

  useEffect(() => {
    const section = searchParams.get('section')
    if (section) {
      const refMap = {
        privacy: privacyRef,
        terms: termsRef,
        contacts: contactsRef,
      }

      const targetRef = refMap[section as keyof typeof refMap]
      if (targetRef?.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth' })
        setActiveSection(section)
      }
    }
  }, [searchParams])

  const handleBack = () => {
    navigate(-1)
  }

  const NavLink = ({
    children,
    onClick,
    isActive,
  }: {
    children: React.ReactNode
    onClick: () => void
    isActive: boolean
  }) => (
    <button
      onClick={onClick}
      className={cn(
        'w-full text-left transition-colors hover:text-light-green-500',
        isActive ? 'text-light-green-500' : 'text-light-green-50/60',
      )}
    >
      <Typography variant="base-semibold">{children}</Typography>
    </button>
  )

  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>RAMO - Legal Information</title>
      </Helmet>

      <div className="sticky top-0 z-30 flex items-center justify-between bg-gray-900 px-4 py-4 md:px-8">
        <SecondaryButton onClick={handleBack} size="sm" leftIcon={<IconArrowLeft size={20} />}>
          Back
        </SecondaryButton>
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="block rounded p-2 text-light-green-50 hover:bg-gray-800 md:hidden"
        >
          <IconMenu2 size={24} />
        </button>
      </div>

      <div className="flex flex-col md:flex-row">
        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div className="fixed inset-0 z-20 bg-gray-900/50 md:hidden" onClick={() => setIsMobileMenuOpen(false)} />
        )}

        {/* Navigation */}
        <nav
          className={cn(
            'fixed z-20 w-64 transform bg-gray-900 transition-transform duration-300 ease-in-out md:sticky md:top-20 md:block md:h-fit md:transform-none',
            isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0',
          )}
        >
          <div className="flex h-screen flex-col space-y-6 p-8 md:h-auto">
            <NavLink onClick={() => scrollToSection(privacyRef, 'privacy')} isActive={activeSection === 'privacy'}>
              Privacy Notice
            </NavLink>
            <NavLink onClick={() => scrollToSection(termsRef, 'terms')} isActive={activeSection === 'terms'}>
              Terms & Conditions
            </NavLink>
            <NavLink onClick={() => scrollToSection(contactsRef, 'contacts')} isActive={activeSection === 'contacts'}>
              Contacts
            </NavLink>
          </div>
        </nav>

        {/* Content */}
        <div className="flex-1 space-y-16 p-4 text-light-green-50 md:p-8">
          <div ref={privacyRef} className="scroll-mt-20 space-y-6">
            <Typography variant="xl-bold">Privacy Notice</Typography>
            <Typography variant="base">Last updated: November 26, 2024</Typography>
            <Typography variant="base">
              At RAMO, we take your privacy seriously. This Privacy Notice explains how we collect, use, disclose, and
              safeguard your information when you use our carbon platform services.
            </Typography>

            <div className="space-y-4">
              <Typography variant="lg">1. Information We Collect</Typography>
              <Typography variant="base">
                We collect several types of information for various purposes to provide and improve our services:
              </Typography>
              <div className="ml-4 space-y-2">
                <Typography variant="base">
                  1.1. Personal Information: • Full name and title • Email address and contact information • Phone
                  numbers • Business address • Company information and role • Payment information
                </Typography>
                <Typography variant="base">
                  1.2. Technical Data: • IP address • Browser type and version • Device information • Usage patterns and
                  preferences • Cookies and similar tracking technologies
                </Typography>
                <Typography variant="base">
                  1.3. Project Data: • Carbon offset project details • Environmental impact assessments • Verification
                  documents • Historical project data
                </Typography>
              </div>
            </div>

            <div className="space-y-4">
              <Typography variant="lg">2. How We Use Your Information</Typography>
              <Typography variant="base">
                We use the collected information for the following purposes: • To provide and maintain our services • To
                notify you about changes to our services • To provide customer support • To gather analysis or valuable
                information to improve our services • To monitor the usage of our services • To detect, prevent and
                address technical issues • To fulfill any other purpose for which you provide it
              </Typography>
            </div>

            <div className="space-y-4">
              <Typography variant="lg">3. Data Protection</Typography>
              <Typography variant="base">
                We implement appropriate technical and organizational security measures to protect your personal data
                against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access. These
                measures include: • Encryption of data in transit and at rest • Regular security assessments • Access
                controls and authentication procedures • Employee training on data protection • Regular backup
                procedures
              </Typography>
            </div>
          </div>

          <div ref={termsRef} className="scroll-mt-20 space-y-6">
            <Typography variant="xl-bold">Terms & Conditions</Typography>
            <Typography variant="base">Last updated: November 26, 2024</Typography>

            <div className="space-y-4">
              <Typography variant="lg">1. Acceptance of Terms</Typography>
              <Typography variant="base">
                By accessing and using the RAMO platform, you acknowledge that you have read, understood, and agree to
                be bound by these Terms and Conditions. If you disagree with any part of these terms, you may not access
                the service.
              </Typography>
            </div>

            <div className="space-y-4">
              <Typography variant="lg">2. Platform Access and Security</Typography>
              <Typography variant="base">
                2.1. Registration Requirements: • You must be at least 18 years old to use our services • You must
                provide accurate and complete registration information • You are responsible for maintaining the
                confidentiality of your account credentials • You must notify us immediately of any unauthorized access
              </Typography>
              <Typography variant="base">
                2.2. Account Security: • You are responsible for all activities under your account • We reserve the
                right to suspend or terminate accounts for violations • Regular security audits are conducted to protect
                user data
              </Typography>
            </div>

            <div className="space-y-4">
              <Typography variant="lg">3. Intellectual Property Rights</Typography>
              <Typography variant="base">
                3.1. Platform Content: • All content on the platform is owned by RAMO or its licensors • Users may not
                copy, modify, or distribute content without permission • Any unauthorized use may result in legal action
              </Typography>
              <Typography variant="base">
                3.2. User Content: • You retain ownership of your content • You grant RAMO a license to use your content
                for service provision • You are responsible for ensuring your content doesn&apos;t infringe others&apos;
                rights
              </Typography>
            </div>

            <div className="space-y-4">
              <Typography variant="lg">4. Limitation of Liability</Typography>
              <Typography variant="base">
                4.1. Service Availability: • We strive for 99.9% uptime but don't guarantee uninterrupted service •
                We&apos;re not liable for any losses due to service interruptions • Scheduled maintenance will be
                announced in advance
              </Typography>
              <Typography variant="base">
                4.2. Disclaimer: • Services are provided "as is&quot; without any warranty • We&apos;re not liable for
                any indirect, consequential, or punitive damages • Total liability is limited to the amount paid for
                services
              </Typography>
            </div>
          </div>

          <div ref={contactsRef} className="scroll-mt-20 space-y-6">
            <Typography variant="xl-bold">Contacts</Typography>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div className="space-y-4 rounded-lg bg-gray-800 p-6">
                <Typography variant="lg">Technical Support</Typography>
                <Typography variant="base">Email: info@ramo.earth</Typography>
                <Typography variant="base">Broadway #1323 New York, NY 10024</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalPage
