import { Loader } from 'lucide-react'
import { Navigate } from 'react-router'
import useAuth from 'src/enviroment/hooks/useAuth'
import { ROUTES } from 'src/types/routes'
import { ONBOARDING_STATUS } from 'src/types/user'

const ProtectedRoute = ({ children, allowedRoles = [] }: { children: React.ReactNode; allowedRoles?: string[] }) => {
  const { isAuthenticated, userRole, isSuperAdmin, isLoading, onboardingStatus } = useAuth()

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.SIGN_IN} replace state={{ from: window.location.pathname }} />
  }

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader className="h-6 w-6 animate-spin text-gray-400" />
      </div>
    )
  }

  if (onboardingStatus === ONBOARDING_STATUS.TELL_US_ABOUT_YOURSELF && isAuthenticated) {
    return <Navigate to={ROUTES.PERSONAL_INFO} replace />
  }

  if (onboardingStatus === ONBOARDING_STATUS.WAIT && isAuthenticated) {
    return <Navigate to={ROUTES.REQUEST_SENT} replace />
  }

  if (allowedRoles.length > 0 && !isSuperAdmin && !allowedRoles.includes(userRole)) {
    return <Navigate to={ROUTES.PROJECTS} replace />
  }

  return <>{children}</>
}

export default ProtectedRoute
