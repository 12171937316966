import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import AuthHeader from 'src/components/molecules/AuthHeader'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { z } from 'zod'
import { signInSchema } from 'src/enviroment/validation/schema'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { Checkbox } from 'src/components/atoms/buttons/CheckBoxButton'
import { ErrorMessage } from 'src/components/atoms/ErrorMessage'
import { on } from 'events'
import { ROUTES } from 'src/types/routes'

type SignInFormData = z.infer<typeof signInSchema>

export interface SignInTemplateProps {
  onSubmit: SubmitHandler<SignInFormData>
  onForgotPassword: () => void
  isLoading?: boolean
  defaultEmail?: string | null
}

const SignInTemplate: React.FC<SignInTemplateProps> = ({
  onSubmit,
  onForgotPassword,
  isLoading = false,
  defaultEmail,
}) => {
  const navigate = useNavigate()
  const defaultValues = { email: defaultEmail || '', password: '', isRemember: false } satisfies SignInFormData
  const methods = useForm<SignInFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(signInSchema),
  })

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>RAMO - Log In</title>
      </Helmet>
      <AuthWrapper>
        <div className="space-y-8">
          <AuthHeader
            title="Sign in to"
            subtitle="Welcome back to RAMO, please enter your login details below to using the app"
          />

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Controller
              name="email"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Email Address"
                  autoFocus
                  type="email"
                  errorMessage={formState.errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Password"
                  type="password"
                  errorMessage={formState.errors.password?.message}
                />
              )}
            />

            <div className="row flex items-center justify-between text-center align-middle">
              <Controller
                name="isRemember"
                control={methods.control}
                render={({ field: { onChange } }) => <Checkbox onCheckedChange={onChange} label="Keep me logged in" />}
              />

              <Typography
                onClick={onForgotPassword}
                variant="base-semibold"
                className="cursor-pointer text-light-green-500"
              >
                Forgot Password?
              </Typography>
            </div>
            <div className="space-y-4">
              <PrimaryButton type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                Log In
              </PrimaryButton>
            </div>
          </form>

          <div className="text-center">
            <Typography variant="base" className="flex flex-row justify-center text-light-green-50">
              Don&apos;t have an account?{' '}
              <Typography
                variant="base-semibold"
                className="ml-2 cursor-pointer text-light-green-500 underline hover:text-light-green-400"
                onClick={() => navigate(ROUTES.SIGN_UP)}
              >
                Sign up
              </Typography>
            </Typography>
          </div>
        </div>
      </AuthWrapper>
    </FormProvider>
  )
}

export default SignInTemplate
