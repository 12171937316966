import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { ChatMessage } from '../../enviroment/api/chat'

interface ExtendedChatMessage extends ChatMessage {
  isError?: boolean
}

interface ChatStore {
  messages: ExtendedChatMessage[]
  addMessage: (message: ExtendedChatMessage) => void
  addErrorMessage: (error: any) => void
  clearMessages: () => void
  initializeMessages: () => void
}

export const useChatStore = create<ChatStore>()(
  persist(
    (set) => ({
      messages: [],
      addMessage: (message) =>
        set((state) => ({
          messages: [...state.messages, message],
        })),
      addErrorMessage: (error) => {
        let errorMessage = 'An error occurred while processing your request.'
        console.log(error)
        try {
          if (typeof error.error === 'string') {
            const innerMatch = error.error.match(/Error message: ({[^}]+})/)
            if (innerMatch) {
              const innerError = JSON.parse(innerMatch[1])
              errorMessage = innerError.error.message
            }
          }
        } catch (e) {
          errorMessage = error?.message || errorMessage
        }

        set((state) => ({
          messages: [
            ...state.messages,
            {
              id: Date.now().toString(),
              role: 'assistant',
              content: errorMessage,
              date: new Date().toISOString(),
              isError: true,
            },
          ],
        }))
      },
      clearMessages: () => set({ messages: [] }),
      initializeMessages: () =>
        set((state) => ({
          messages:
            state.messages.length === 0
              ? [
                  {
                    id: 'welcome',
                    role: 'assistant',
                    content:
                      "Hi! I'm Copa, your carbon copilot. Policies, methodologies, and carbon markets can feel like a dense forest, so I'm here to shed some light on your questions. What would you like to know?",
                    date: new Date().toISOString(),
                  },
                ]
              : state.messages,
        })),
    }),
    {
      name: 'chat-storage',
    },
  ),
)
