import { FC, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useQueryClient } from '@tanstack/react-query'
import SuperAdminTemplate from 'src/templates/dashboard/super-admin/SuperAdminTemplate'
import {
  SuperAdminQueryKeys,
  useApproveUser,
  useGlobalProjectQuery,
  useGlobalTenantsQuery,
  useRefreshThumbnailMutation,
  useUsersQuery,
} from 'src/enviroment/api/services/superAdminApi'
import { useDebounceValue } from 'src/enviroment/hooks/useDebounceValue'

const SuperAdminPage: FC = () => {
  const queryClient = useQueryClient()
  const [searchTermUsers, setSearchTermUsers] = useState<string>('')
  const [searchTermProjects, setSearchTermProjects] = useState<string>('')
  const [searchTermTenants, setSearchTermTenants] = useState<string>('')

  const [approvalFilter, setApprovalFilter] = useState<'approved' | 'not_approved' | null>(null)

  const debouncedSearchUsers = useDebounceValue(searchTermUsers, 800)
  const debouncedSearchProjects = useDebounceValue(searchTermProjects, 800)
  const debouncedSearchTenants = useDebounceValue(searchTermTenants, 800)

  const debouncedApprovalFilter = useDebounceValue(approvalFilter, 300)

  useEffect(() => {
    queryClient.invalidateQueries([SuperAdminQueryKeys.UsersList, debouncedSearchUsers, debouncedApprovalFilter])
  }, [debouncedSearchUsers, debouncedApprovalFilter, queryClient])

  useEffect(() => {
    queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalProjectList, debouncedSearchProjects])
  }, [debouncedSearchProjects, queryClient])

  useEffect(() => {
    queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalTenantsList, debouncedSearchTenants])
  }, [debouncedSearchTenants, queryClient])

  const { data: projects } = useGlobalProjectQuery({
    query: debouncedSearchProjects,
  })

  const {
    data: tenants,
    fetchNextPage: fetchNextPageTenants,
    isFetchingNextPage: isFethingNextPageGlobalTenants,
    hasNextPage: isHasNextPageGlobalTenants,
    isLoading: isLoadingGlobalTenant,
    isFetchingNextPage: isFetchingNextPageTenants,
  } = useGlobalTenantsQuery({
    query: searchTermTenants,
  })
  const {
    data: users,
    isLoading,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isFetchingNextPage: isFetchingNextPageUsers,
  } = useUsersQuery({
    isApproved:
      debouncedApprovalFilter === 'approved' ? true : debouncedApprovalFilter === 'not_approved' ? false : undefined,
    query: debouncedSearchUsers,
  })

  const handleRefreshThumbnail = useRefreshThumbnailMutation()
  const approveMutation = useApproveUser()

  return (
    <>
      <Helmet>
        <title>RAMO - Platform Admin</title>
      </Helmet>

      <SuperAdminTemplate
        isLoading={isLoading}
        error={undefined}
        currentFilter={approvalFilter}
        users={users?.pages.flatMap((page) => page.users)}
        projects={projects?.pages.flatMap((page) => page.projects)}
        tenants={tenants?.pages.flatMap((page) => page.tenants)}
        searchTermUsers={searchTermUsers}
        searchTermProjects={searchTermProjects}
        hasNextPageUsers={hasNextPageUsers}
        isFetchingNextPageUsers={isFetchingNextPageUsers}
        fetchNextPageUsers={fetchNextPageUsers}
        onSearchUsers={(e) => setSearchTermUsers(e.target.value)}
        onSearchProjects={(e) => setSearchTermProjects(e.target.value)}
        onApproveUser={(userId) => approveMutation.mutateAsync(userId)}
        onFilterChange={setApprovalFilter}
        onRefreshThumbnail={(projectId) => handleRefreshThumbnail.mutateAsync(projectId)}
        searchTemTenants={searchTermTenants}
        onSearchTenants={(e) => setSearchTermTenants(e.target.value)}
        isFethingNextPageGlobalTenants={isFethingNextPageGlobalTenants}
        isHasNextPageGlobalTenants={isHasNextPageGlobalTenants}
        isFetchingNextPageTenants={isFetchingNextPageTenants}
        fetchNextPageTenants={fetchNextPageTenants}
        isLoadingGlobalTenant={isLoadingGlobalTenant}
      />
    </>
  )
}

export default SuperAdminPage
