import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { client } from '../axios'
import { queryTool } from './superAdminApi'
import { useTenantStore } from 'src/enviroment/store/tenentStore'

export const TagsQueryKeys = {
  Types: 'types',
  Locations: 'locations',
} as const

export interface Types {
  id: string
  name: string
}

interface TagSearchParams {
  query?: string
}

export function useSearchTypes(params: TagSearchParams = {}) {
  const { query = '' } = params

  return useQuery({
    queryKey: [TagsQueryKeys.Types, query],
    queryFn: async () => {
      const response = await client.get<Types[]>('/types', {
        params: {
          query: queryTool(query),
        },
      })
      return response.data
    },
  })
}

export function useGetTypesMyProjects() {
  const tenantId = useTenantStore((state) => state.currentTenant?.id)

  return useQuery({
    queryKey: [TagsQueryKeys.Types],
    queryFn: async () => {
      const response = await client.get<Types[]>(`/tenant/${tenantId}/types:my-projects`)
      return response.data
    },
  })
}

export function useGetLocationsMyProjects() {
  const tenantId = useTenantStore((state) => state.currentTenant?.id)

  return useQuery({
    queryKey: [TagsQueryKeys.Locations],
    queryFn: async () => {
      const response = await client.get<Types[]>(`/tenants/${tenantId}/projects:locations`)
      return response.data
    },
  })
}

export function useCreateType() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (name: string) => {
      const response = await client.post<Types>('/types', { name })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TagsQueryKeys.Types] })
    },
  })
}

export function useDeleteTypeTag() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      await client.delete(`/types/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TagsQueryKeys.Types] })
    },
  })
}
