import { RouteObject, Navigate, createBrowserRouter } from 'react-router'
import {
  ErrorPage,
  SignInPage,
  SignUpPage,
  SignUpPersonalInfoPage,
  AtlasPage,
  TeamPage,
  RequestSentPage,
  ForgotPasswordPage,
  ForgotPasswordSentPage,
  SetNewPasswordPage,
  ProjectDetailsPage,
  MyProfilePage,
  LegalPage,
  SuperAdminPage,
  MyProjectsPage,
} from 'src/pages'

import DashboardLayout from 'src/enviroment/layouts/dashboard-layout'
import useAuth from 'src/enviroment/hooks/useAuth'
import ProtectedRoute from './protected-route'
import { ROUTES } from 'src/types/routes'
import { UserRole } from 'src/types/user'

const RootRedirect = () => {
  const { isAuthenticated } = useAuth()
  return isAuthenticated ? <Navigate to="/dashboard/projects" replace /> : <Navigate to="/auth/sign-in" replace />
}

const LogoutPage = () => {
  const { logout } = useAuth()
  logout()
  return <Navigate to="/auth/sign-in" replace />
}

const publicRoutes: RouteObject[] = [
  {
    path: ROUTES.HOME,
    element: <RootRedirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SIGN_IN,
    element: <SignInPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SIGN_UP,
    element: <SignUpPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.PERSONAL_INFO,
    element: <SignUpPersonalInfoPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.REQUEST_SENT,
    element: <RequestSentPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD_SENT,
    element: <ForgotPasswordSentPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.SET_NEW_PASSWORD,
    element: <SetNewPasswordPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.LOGOUT,
    element: <LogoutPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.LEGAL,
    element: <LegalPage />,
    errorElement: <ErrorPage />,
  },
]

const protectedRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'projects',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR, UserRole.VIEWER]}>
            <MyProjectsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'profile',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR, UserRole.VIEWER]}>
            <MyProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'project/:id',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR, UserRole.VIEWER]}>
            <ProjectDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'atlas',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN, UserRole.CONTRIBUTOR]}>
            <AtlasPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'team',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN]}>
            <TeamPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'superadmin',
        element: (
          <ProtectedRoute allowedRoles={[UserRole.ADMIN]}>
            <SuperAdminPage />
          </ProtectedRoute>
        ),
      },
      {
        path: '',
        element: <Navigate to="projects" replace />,
      },
    ],
  },
]

const routes: RouteObject[] = [...publicRoutes, ...protectedRoutes]

export function createRouter() {
  return createBrowserRouter(routes, {
    future: {
      v7_relativeSplatPath: true,
      v7_startTransition: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  })
}
