import { Helmet } from 'react-helmet-async'
import Typography from 'src/components/atoms/Typography'
import { MapMouseEvent } from 'mapbox-gl'
import { useGetAtlasProjects } from 'src/enviroment/api/services/projectsApi'
import { useCallback, useState, useMemo } from 'react'
import { Types, useGetLocationsMyProjects, useGetTypesMyProjects } from 'src/enviroment/api/services/typesApi'
import { useGetStagesFromMyProject } from 'src/enviroment/api/services/stageApi'
import CombinedMapBox from 'src/components/organisms/mapbox/CombinedMapBox'
import FilterDropdown from 'src/components/atoms/FilterDropDown'

const AtlasPage: React.FC = () => {
  const [selectedTypesIds, setSelectedTypesIds] = useState<string[]>([])
  const [selectedStagesIds, setSelectedStagesIds] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const { data: projects, isLoading } = useGetAtlasProjects({
    typeIds: selectedTypesIds,
    stageIds: selectedStagesIds,
    location: selectedLocations,
  })

  const { data: types } = useGetTypesMyProjects()
  const { data: stages } = useGetStagesFromMyProject()
  const { data: locations } = useGetLocationsMyProjects()

  const formattedLocations = useMemo(
    () =>
      locations?.map((location) => ({
        id: location,
        name: location,
      })) as unknown as Types[],
    [locations],
  )

  const handleMapClick = useCallback((e: MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] }) => {
    console.log('Clicked at:', e.lngLat)
  }, [])

  const handleAreaCreated = useCallback((area: GeoJSON.Feature[]) => {
    console.log('Area to send:', area)
  }, [])

  return (
    <>
      <Helmet>
        <title>RAMO - Atlas</title>
      </Helmet>

      <div className="flex h-full flex-col">
        <div className="flex flex-row items-center justify-between px-5 py-3 text-gray-50">
          <Typography variant="xl">Atlas</Typography>
        </div>

        <div className="relative flex-1">
          <div className="absolute right-20 top-4 z-10 flex gap-2 p-2 backdrop-blur">
            {types && (
              <FilterDropdown
                type="Types"
                theme="light"
                options={types}
                onChange={(ids) => setSelectedTypesIds(ids)}
                className="w-full min-w-[150px] sm:w-[150px]"
              />
            )}
            {stages && (
              <FilterDropdown
                type="Stages"
                theme="light"
                options={stages}
                onChange={(ids) => setSelectedStagesIds(ids)}
                className="w-full min-w-[150px] sm:w-[150px]"
              />
            )}
            {formattedLocations && (
              <FilterDropdown
                type="Location"
                theme="light"
                options={formattedLocations}
                onChange={(ids) => setSelectedLocations(ids)}
                className="w-full min-w-[150px] sm:w-[150px]"
              />
            )}
          </div>
          <CombinedMapBox
            initialOptions={{
              style: 'mapbox://styles/mapbox/standard-satellite',
              projection: 'globe',
            }}
            dataForFilters={{
              types,
              stages,
              locations: formattedLocations,
            }}
            methodsForFilters={{
              setSelectedTypesIds,
              setSelectedStagesIds,
              setSelectedLocations,
            }}
            projects={projects}
            isLoading={isLoading}
            onAreaCreated={handleAreaCreated}
            onMapClick={handleMapClick}
          />
        </div>
      </div>
    </>
  )
}

export default AtlasPage
