import { IconTrash } from '@tabler/icons-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from '../../atoms/alert-dialog'
import { FC, useCallback, useState } from 'react'
import { Member } from 'src/templates/members/columns'
import { IUser } from 'src/types/user'
import { useDeleteUser } from 'src/enviroment/api/services/superAdminApi'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'

interface Props {
  member: Member | IUser
}

const DeleteUserDialog: FC<Props> = ({ member }) => {
  const [opened, setOpened] = useState(false)
  const [confirmName, setConfirmName] = useState('')
  const handleClose = useCallback(() => {
    setOpened(false)
    setConfirmName('')
  }, [])

  const { mutateAsync: deleteMemberAsync, isLoading } = useDeleteUser()

  const fullName = `${member.firstName} ${member.lastName}`
  const isConfirmed = confirmName === fullName

  const handleDelete = useCallback(async () => {
    if (!isConfirmed) return
    try {
      await deleteMemberAsync(member.id)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }, [deleteMemberAsync, handleClose, member.id, isConfirmed])

  return (
    <AlertDialog open={opened} onOpenChange={setOpened}>
      <AlertDialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-red-600 transition-all hover:cursor-pointer hover:bg-red-600 hover:text-white active:scale-95">
        <IconTrash size={24} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <div className="flex flex-col items-center gap-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFADAD]">
            <IconTrash className="h-6 w-6 text-red-600" />
          </div>
          <div className="flex flex-col justify-center gap-6">
            <Typography variant="xl" className="text-center text-green-50">
              Delete RAMO Member
            </Typography>
            <Typography variant="base" className="text-center text-gray-50">
              You&apos;re about to delete the{' '}
              <Typography variant="base-semibold" className="inline">
                {fullName}
              </Typography>{' '}
              user. Please type their full name to confirm deletion.
            </Typography>
            <Input
              value={confirmName}
              onChange={(e) => setConfirmName(e.target.value)}
              placeholder="Enter full name"
              className="text-center"
            />
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading} onClick={handleClose} className="w-40">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoading || !isConfirmed}
            onClick={handleDelete}
            className="w-40 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteUserDialog
