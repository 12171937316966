import React, { useCallback } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select'
import { cn } from 'src/enviroment/lib/utils'
import { plans } from 'src/enviroment/constants/constants'
import { ITenant, SUBSCRIPTION_PLAN } from 'src/types/user'
import { useUpdateTenantAdmin } from 'src/enviroment/api/services/superAdminApi'

type Plan = (typeof plans)[number]['value']

interface PlanSelectProps {
  value: SUBSCRIPTION_PLAN
  tenant: ITenant
  className?: string
}

const PlanSelect = ({ value, tenant, className }: PlanSelectProps) => {
  const { mutateAsync } = useUpdateTenantAdmin()
  const getCurrentRoleLabel = useCallback(() => {
    return plans.find((plan) => plan.value === value)?.label || 'Select Role'
  }, [value])

  const onRoleChange = useCallback(
    async (newPlan: Plan) => {
      await mutateAsync({ id: tenant.id, plan: newPlan })
    },
    [mutateAsync, tenant.id],
  )
  return (
    <Select value={value} onValueChange={(newValue: Plan) => onRoleChange(newValue)}>
      <SelectTrigger className={cn('w-full font-inter text-base', className)}>
        <SelectValue>{getCurrentRoleLabel()}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {plans.map((role) => (
          <SelectItem key={role.value} value={role.value} className="cursor-pointer font-inter text-base">
            {role.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default PlanSelect
