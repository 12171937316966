import Typography from 'src/components/atoms/Typography'
import { formatArea } from 'src/enviroment/lib/utils'
import { DrawnAreasState } from 'src/types/mapbox'
import RequestProjectDrawDialog from '../../dialogs/RequestProjectDrawDialog'
import CreateProjectDrawDialog from '../../dialogs/CreateProjectDrawDialog'

export const AreasInfo: React.FC<{
  drawnAreas: DrawnAreasState
  countryName: string | null
  getDrawnAreasFile: () => File | null
}> = ({ drawnAreas, countryName, getDrawnAreasFile }) => (
  <div className="absolute bottom-4 left-1/2 z-10 -translate-x-1/2 transform rounded-lg bg-white p-1 shadow-lg">
    <div className="flex items-center justify-between space-x-5 px-4">
      <Typography variant="base-semibold">
        {formatArea(drawnAreas.totalHectares)}
        {drawnAreas.features.length > 1 && ` (${drawnAreas.features.length} areas)`}
      </Typography>
      <RequestProjectDrawDialog initialFile={getDrawnAreasFile()} />
      {/* <CreateProjectDrawDialog initialFile={getDrawnAreasFile()} location={countryName} /> */}
    </div>
  </div>
)
