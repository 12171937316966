import React from 'react'
import { Outlet } from 'react-router'
import Sidebar from '../../components/organisms/SideBar'
import Footer from 'src/components/molecules/Footer'
import { useLocalStorage } from 'react-use'
import ChatWidget from 'src/components/organisms/ChatWidget'
import { useTenantStore } from '../store/tenentStore'
import { SUBSCRIPTION_PLAN } from 'src/types/user'

interface DashboardLayoutProps {
  children?: React.ReactNode
}

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage<boolean>('sidebar-collapsed', false)
  const isFreePlan = useTenantStore((state) => state.currentTenant.plan) === SUBSCRIPTION_PLAN.FREE

  return (
    <div className="flex min-h-screen w-full">
      <Sidebar isCollapsed={Boolean(isCollapsed || false)} onCollapse={(value: boolean) => setIsCollapsed(value)} />
      <div
        className={`
          flex flex-1 flex-col 
          transition-all duration-300
          ${isCollapsed ? 'ml-[88px]' : 'ml-64'}
        `}
      >
        <main className="flex-1 overflow-auto bg-gray-900">
          <Outlet />
          {!isFreePlan && <ChatWidget />}
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default DashboardLayout
