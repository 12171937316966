import React from 'react'
import AddAttachmentButton from '../atoms/buttons/AddAttachmentButton'
import FileUploadStatus from '../molecules/FileUploadStatus'

interface FileUploadContainerProps {
  file: File | null
  onFileSelect: (file: File) => void
  onFileRemove: () => void
}

const FileUploadContainer: React.FC<FileUploadContainerProps> = ({ file, onFileSelect, onFileRemove }) => {
  return file ? (
    <FileUploadStatus file={file} onRemove={onFileRemove} />
  ) : (
    <AddAttachmentButton onFileSelect={onFileSelect} />
  )
}

export default FileUploadContainer
