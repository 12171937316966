import React, { useCallback, useRef, useState } from 'react'
import { IconFileUpload, IconX } from '@tabler/icons-react'
import Typography from 'src/components/atoms/Typography'

interface FileDropzoneProps {
  onFilesChange: (files: File[]) => void
  files: File[]
}

const FileDropzone: React.FC<FileDropzoneProps> = ({ onFilesChange, files }) => {
  const [isDragging, setIsDragging] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(true)
  }, [])

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }, [])

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setIsDragging(false)

      const droppedFiles = Array.from(e.dataTransfer.files)
      onFilesChange([...files, ...droppedFiles])
    },
    [files, onFilesChange],
  )

  const handleFileSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const selectedFiles = Array.from(e.target.files)
        onFilesChange([...files, ...selectedFiles])
      }
    },
    [files, onFilesChange],
  )

  const removeFile = useCallback(
    (index: number) => {
      onFilesChange(files.filter((_, i) => i !== index))
    },
    [files, onFilesChange],
  )

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B'
    const k = 1024
    const sizes = ['B', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
  }

  return (
    <div className="space-y-4">
      <div
        className={`relative flex min-h-[200px] cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed p-6 transition-colors ${
          isDragging ? 'border-light-green-500 bg-gray-800' : 'border-gray-300 bg-gray-800'
        }`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileSelect}
          multiple
          accept=".geojson,.kml,.kmz,.shp"
        />
        <div className="mb-4 rounded-lg border border-light-green-500 bg-light-green-100 p-2">
          <IconFileUpload size={40} className="text-light-green-500" stroke={1.5} />
        </div>
        <Typography variant="base-bold" className="mb-2 text-center text-light-green-50">
          Drop files here or click to upload
        </Typography>
        <Typography variant="sm" className="text-center text-gray-100">
          Upload a GeoJSON, Shapefile, KMZ, or KML to begin analyzing
        </Typography>
        <Typography variant="xs" className="mt-2 text-center text-gray-100">
          If you have a spatial file for your project boundary, upload it. If not, simply draw your boundary directly on
          the map to get started.
        </Typography>
      </div>

      {files.length > 0 && (
        <div className="space-y-2">
          {files.map((file, index) => (
            <div key={index} className="flex w-full items-center gap-3 rounded-lg bg-gray-800 p-3">
              <div className="flex-shrink-0">
                <div className="rounded-lg border border-light-green-500 bg-light-green-100 p-2">
                  <IconFileUpload size={24} className="text-light-green-500" stroke={1.5} />
                </div>
              </div>
              <div className="flex flex-1 flex-col">
                <div className="flex items-center justify-between">
                  <Typography variant="sm" className="text-light-green-50">
                    {file.name}
                  </Typography>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      removeFile(index)
                    }}
                    className="text-light-green-50 hover:text-light-green-100"
                  >
                    <IconX size={16} stroke={1.5} />
                  </button>
                </div>
                <Typography variant="xs" className="text-gray-400">
                  {formatFileSize(file.size)}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FileDropzone
