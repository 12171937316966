import { FC, ReactElement } from 'react'
import Typography from 'src/components/atoms/Typography'
import ProjectGrid from 'src/components/organisms/ProjectGrid'
import DashboardHeader from 'src/components/molecules/DashboardHeader'
import Loader from 'src/components/atoms/Loader'
import { Project } from 'src/types/projects'
import RequestProjectDialog from 'src/components/organisms/dialogs/RequestProjectDialog'
import { IconSearch } from '@tabler/icons-react'
import { Input } from 'src/components/atoms/inputs/Input'
import FilterDropdown from 'src/components/atoms/FilterDropDown'
import { Types } from 'src/enviroment/api/services/typesApi'
import { Stage } from 'src/components/organisms/StageSelector'

interface ProjectsTemplateProps {
  projects: Project[]
  searchTerm: string
  setSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLoading: boolean
  error: unknown
  selectedTypesIds: string[]
  setSelectedTypesIds: (ids: string[]) => void
  selectedStagesIds: string[]
  selectedLocations: string[]
  setSelectedStagesIds: (ids: string[]) => void
  setSelectedLocations: (ids: string[]) => void
  isFetching: boolean
  types: Types[] | undefined
  stages: Stage[] | undefined
  locations: Types[] | undefined
  onOpenProject: (project: Project) => void
}

const ProjectsTemplate: FC<ProjectsTemplateProps> = ({
  projects,
  isLoading,
  error,
  isFetching,
  types,
  stages,
  searchTerm,
  locations,
  setSelectedLocations,
  setSelectedStagesIds,
  setSelectedTypesIds,
  setSearchTerm,
  onOpenProject,
}): ReactElement => {
  return (
    <div className="min-h-screen w-full">
      <DashboardHeader title="My Projects" rightSide={<RequestProjectDialog />} />

      {/* Search and Filters Section */}
      <div className="flex flex-col space-y-4 px-4 pt-4 md:flex-row md:items-center md:justify-between md:space-y-0 md:px-8">
        <Input
          containerClassName="w-full md:w-[60%] lg:w-[50%]"
          icon={<IconSearch />}
          className="h-11 border-gray-500 bg-transparent"
          placeholder="Search"
          onChange={setSearchTerm}
          value={searchTerm}
        />

        {/* Filters */}
        <div className="flex flex-wrap gap-2 sm:flex-nowrap">
          {stages && (
            <FilterDropdown
              type="Stages"
              options={stages}
              onChange={setSelectedStagesIds}
              className="w-full min-w-[200px] sm:w-[200px]"
            />
          )}
          {types && (
            <FilterDropdown
              type="Types"
              options={types}
              onChange={setSelectedTypesIds}
              className="w-full min-w-[200px] sm:w-[200px]"
            />
          )}
          {locations && (
            <FilterDropdown
              type="Locations"
              options={locations}
              onChange={setSelectedLocations}
              className="w-full min-w-[200px] sm:w-[200px]"
            />
          )}
        </div>
      </div>

      {/* Loading State */}
      {isLoading && (
        <div className="flex h-[50vh] items-center justify-center pt-10 text-gray-50">
          <Loader />
        </div>
      )}

      {/* Empty State */}
      {!isLoading && !isFetching && !error && projects.length === 0 && (
        <div className="mt-10 flex h-[50vh] flex-col items-center justify-center space-y-2 px-4 text-center text-light-green-50">
          <Typography variant="base">You don&apos;t have any projects created yet.</Typography>
          <Typography variant="base">Create your first project, and it will immediately appear in this tab.</Typography>
        </div>
      )}

      {/* Projects Grid */}
      {!isLoading && !error && projects.length > 0 && (
        <div className="space-y-8 p-4 md:p-8">
          <ProjectGrid projects={projects} onOpenProject={onOpenProject} />
        </div>
      )}
    </div>
  )
}

export default ProjectsTemplate
