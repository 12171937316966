import { Paperclip, X } from 'lucide-react'

interface FileUploadStatusProps {
  file: File
  onRemove: () => void
}

const FileUploadStatus: React.FC<FileUploadStatusProps> = ({ file, onRemove }) => {
  const sizeInMB = file.size / 1024 / 1024
  const sizeInKB = file.size / 1024
  const fileSize = sizeInMB < 1 ? `${sizeInKB.toFixed(2)} KB` : `${sizeInMB.toFixed(2)} MB`

  return (
    <div className="flex items-center rounded-lg bg-gray-800 p-3">
      <div className="flex flex-1 items-center gap-2">
        <div className="rounded-full bg-light-green-50/10 p-2">
          <Paperclip className="h-4 w-4 text-light-green-300" />
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-sm text-light-green-50">{file.name}</p>
          <p className="text-xs text-gray-400">{fileSize}</p>
        </div>
        <button
          type="button"
          onClick={onRemove}
          className="rounded-full p-1 hover:bg-gray-700"
          aria-label="Remove file"
        >
          <X className="h-4 w-4 text-gray-400" />
        </button>
      </div>
    </div>
  )
}

export default FileUploadStatus
