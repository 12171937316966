import { ColumnDef } from '@tanstack/react-table'
import { ImageIcon } from 'lucide-react'
import EditWorkspaceDialog from 'src/components/organisms/dialogs/EditWorkSpaceDialog'
import PlanSelect from 'src/components/organisms/PlanSelect'
import { ITenant } from 'src/types/user'

export type Tenant = ITenant

export const useColumns = () => {
  const columns: ColumnDef<Tenant>[] = [
    {
      id: 'logo',
      header: 'Logo',
      cell: ({ row }) => (
        <div className="relative h-16 w-24">
          {row.original.logoUrl ? (
            <img
              src={row.original.logoUrl}
              alt={`${row.original.name} thumbnail`}
              className="h-full w-full rounded object-contain"
              onError={(e) => {
                e.currentTarget.style.display = 'none'
                e.currentTarget.nextElementSibling?.classList.remove('hidden')
              }}
            />
          ) : null}
          <div
            className={`absolute inset-0 flex items-center justify-center rounded bg-secondary ${
              row.original.logoUrl ? 'hidden' : ''
            }`}
          >
            <ImageIcon className="h-6 w-6 text-muted-foreground" />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'id',
      header: 'ID',
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'plan',
      header: 'Plan',
      cell: ({ row }) => (
        <PlanSelect
          value={row.original.plan}
          tenant={row.original}
          className="border-gray-700 bg-transparent text-gray-100"
        />
      ),
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <div className="flex gap-2">
          <EditWorkspaceDialog workspace={row.original} />
        </div>
      ),
    },
  ]

  return columns
}
