import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router'
import AuthHeader from 'src/components/molecules/AuthHeader'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { z } from 'zod'
import { signInSchema, signUpSchema } from 'src/enviroment/validation/schema'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { Checkbox } from 'src/components/atoms/buttons/CheckBoxButton'
import { ROUTES } from 'src/types/routes'
import TermsPrivacy from 'src/components/molecules/TermsPrivacy'

type SignUpFormData = z.infer<typeof signUpSchema>

export interface SingUpTemplateProps {
  onSubmit: SubmitHandler<SignUpFormData>
  handleForgotPassword: () => void
  isLoading?: boolean
  invitedEmail?: string | null
}

const SingUpTemplate: React.FC<SingUpTemplateProps> = ({
  onSubmit,
  handleForgotPassword,
  isLoading = false,
  invitedEmail,
}) => {
  const navigate = useNavigate()

  const defaultValues = {
    email: invitedEmail || '',
    password: '',
    confirmPassword: '',
    isRemember: false,
    marketingMailing: false,
  } satisfies SignUpFormData

  const methods = useForm<SignUpFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(signUpSchema),
  })

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>RAMO - Sign Up</title>
      </Helmet>
      <AuthWrapper>
        <div className="space-y-8">
          <AuthHeader title="Sign up to" subtitle="Create an account and explore the carbon platform" />

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Controller
              name="email"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Email Address"
                  autoFocus
                  autoComplete="new-email"
                  type="email"
                  errorMessage={formState.errors.email?.message}
                />
              )}
            />
            <Controller
              name="password"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  errorMessage={formState.errors.password?.message}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Confirm Password"
                  type="password"
                  errorMessage={formState.errors.confirmPassword?.message}
                />
              )}
            />

            <div className="row flex items-center justify-between text-center align-middle">
              <Controller
                name="isRemember"
                control={methods.control}
                render={({ field: { onChange } }) => <Checkbox onCheckedChange={onChange} label="Keep me logged in" />}
              />

              <Typography
                onClick={handleForgotPassword}
                variant="base-semibold"
                className="cursor-pointer text-light-green-500"
              >
                Forgot Password?
              </Typography>
            </div>
            <div className="space-y-4">
              <PrimaryButton type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                Next
              </PrimaryButton>
            </div>
          </form>
          <div className="row flex items-center justify-between text-center align-middle">
            <Controller
              name="marketingMailing"
              control={methods.control}
              render={({ field: { onChange } }) => (
                <Checkbox onCheckedChange={onChange} label="I consent to receive marketing emails." />
              )}
            />
          </div>
          <div>
            <TermsPrivacy className="text-light-green-50" />
          </div>

          <div className="text-center">
            <Typography variant="base" className="flex flex-row justify-center text-light-green-50">
              Do you already have an account?{' '}
              <Typography
                variant="base-semibold"
                className="ml-2 cursor-pointer text-light-green-500 underline hover:text-light-green-400"
                onClick={() => navigate(ROUTES.SIGN_IN)}
              >
                Log in
              </Typography>
            </Typography>
          </div>
        </div>
      </AuthWrapper>
    </FormProvider>
  )
}

export default SingUpTemplate
